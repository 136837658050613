import React, { useState } from 'react'
import { styled, createTheme, ThemeProvider } from '@mui/material/styles'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { Button } from '@material-ui/core'
import CssBaseline from '@mui/material/CssBaseline'
import MuiDrawer from '@mui/material/Drawer'
import Box from '@mui/material/Box'
import MuiAppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import List from '@mui/material/List'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import Badge from '@mui/material/Badge'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
// import Link from '@mui/material/Link'
import LogoutIcon from '@mui/icons-material/Logout'
import MenuIcon from '@mui/icons-material/Menu'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import NotificationsIcon from '@mui/icons-material/Notifications'
import { mainListItems, secondaryListItems } from '../components/ListItems'
// import Course from './Course.js'

import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import DashboardIcon from '@mui/icons-material/Dashboard';
import GolfCourseIcon from '@mui/icons-material/GolfCourse';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import GroupsIcon from '@mui/icons-material/Groups';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import RoomIcon from '@mui/icons-material/Room';
import BroadcastOnHomeIcon from '@mui/icons-material/BroadcastOnHome';
import SettingsIcon from '@mui/icons-material/Settings';
import InventoryIcon from '@mui/icons-material/Inventory';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';

import AuthService from '../services/authService'
import { Routes, Route, Link } from 'react-router-dom'

const allLinks = [
    {
        linkName: 'Dashboard',
        url: '/',
        icon: <DashboardIcon />
    },
    {
        linkName: 'Package',
        url: '/package',
        icon: <InventoryIcon />
    },
    {
        linkName: 'Courses',
        url: '/courses',
        icon: <GolfCourseIcon />
    },
    {
        linkName: 'Category',
        url: '/category',
        icon: <FormatListBulletedIcon />

    }, {
        linkName: 'Users',
        url: '/users',
        icon: <GroupsIcon />
    }, {
        linkName: 'History',
        url: '#',
        icon: <HistoryEduIcon />
    }, {
        linkName: 'Instructors',
        url: '/instructors',
        icon: <MilitaryTechIcon />
    },
    {
        linkName: 'Location',
        url: '/location',
        icon: <RoomIcon />
    }, {
        linkName: 'Broadcast',
        url: '/broadcast',
        icon: <BroadcastOnHomeIcon />
    }, {
        linkName: 'Settings',
        url: '/settings',
        icon: <SettingsIcon />
    }
];

function Copyright(props) {
    return (
        <Typography
            variant='body2'
            color='text.secondary'
            align='center'
            {...props}
        >
            {'Copyright © '}
            <Link color='inherit' href='https://mui.com/'>
                Adhitya Dwijayantho
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    )
}

const drawerWidth = 240

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: prop => prop !== 'open'
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easingg: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    })
}))

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: prop => prop !== 'open'
})(({ theme, open }) => ({
    '& .MuiDrawer-paper': {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        }),
        boxSizing: 'border-box',
        ...(!open && {
            overflowX: 'hidden',
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen
            }),
            width: theme.spacing(7),
            [theme.breakpoints.up('sm')]: {
                width: theme.spacing(9)
            }
        })
    }
}))

const defaultTheme = createTheme()

const Navbar = () => {
    const navigate = useNavigate()
    const [open, setOpen] = useState(false)
    const toggleDrawer = () => {
        setOpen(!open)
    }

    const logOut = () => {
        AuthService.logout()
        navigate('/login')
    }

    const linkTo = (url) => {
        // alert(url)
        navigate(url)
    }

    return (
        // <>
        //   <h1>Dashboard page</h1>
        // </>
        <>
            <AppBar position='absolute' open={open}>
                <Toolbar
                    sx={{
                        pr: '24px' // keep right padding when drawer closed
                    }}
                >
                    <IconButton
                        edge='start'
                        color='inherit'
                        aria-label='open drawer'
                        onClick={toggleDrawer}
                        sx={{
                            marginRight: '36px',
                            ...(open && { display: 'none' })
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography
                        component='h1'
                        variant='h6'
                        color='inherit'
                        noWrap
                        sx={{ flexGrow: 1 }}
                    >
                        Content Management System
                    </Typography>
                    <IconButton color='inherit'>
                        {/* <Badge badgeContent={4} color='secondary'> */}
                        {/* <NotificationsIcon />
               */}
                        {/* <Button type='text'> */}
                        <LogoutIcon onClick={logOut} />
                        {/* </Button> */}
                        {/* </Badge> */}
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Drawer variant='permanent' open={open}>
                <Toolbar
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        px: [1]
                    }}
                >
                    <IconButton onClick={toggleDrawer}>
                        <ChevronLeftIcon />
                    </IconButton>
                </Toolbar>
                <Divider />
                <List component='nav'>
                    {/* {mainListItems}
          <Divider sx={{ my: 1 }} />
          {secondaryListItems} */}

                    {
                        allLinks.map((lnk, index) => (
                            <ListItem disablePadding>
                                <ListItemButton onClick={() => linkTo(lnk.url)}>
                                    {/* <ListItemIcon>
                                    {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                                </ListItemIcon> */}
                                    <ListItemIcon>
                                        {lnk.icon}
                                    </ListItemIcon>
                                    <ListItemText primary={lnk.linkName} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                </List>
            </Drawer>
        </>
    )
}

export default Navbar
