import React, { useState, useRef } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Link from '@mui/material/Link'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles'

/*** Validation */
import Form from 'react-validation/build/form'
import Input from 'react-validation/build/input'
import CheckButton from 'react-validation/build/button'
import { isEmail } from 'validator'

/*** Services */
import AuthService from '../services/authService';
// import { first } from 'lodash'

const required = value => {
    if (!value) {
        return (
            <div className='alert alert-danger' role='alert'>
                This file is required!
            </div>
        )
    }
}

const vfirstname = value => {
    if (value.length < 3 || value.length > 20) {
        return (
            <div className='alert alert-danger' role='alert'>
                The username must be between 3 and 20 character
            </div>
        )
    }
}

const vlastname = value => {
    if (value.length < 3 || value.length > 20) {
        return (
            <div className='alert alert-danger' role='alert'>
                The username must be between 3 and 20 character
            </div>
        )
    }
}

const validEmail = value => {
    if (!isEmail(value)) {
        return (
            <div className='alert alert-danger' role='alert'>
                This is not a valid email
            </div>
        )
    }
}

const vusername = value => {
    if (value.length < 3 || value.length > 20) {
        return (
            <div className='alert alert-danger' role='alert'>
                The username must be between 3 and 20 characters.
            </div>
        )
    }
}

const vpassword = value => {
    if (value.length < 6 || value.length > 40) {
        return (
            <div className='alert alert-danger' role='alert'>
                The password must be between 6 and 40 characters.
            </div>
        )
    }
}

function Copyright(props) {
    return (
        <Typography
            variant='body2'
            color='text.secondary'
            align='center'
            {...props}
        >
            {'Copyright © '}
            <Link color='inherit' href='https://mui.com/'>
                Adhitya Dwijayantho
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    )
}

const defaultTheme = createTheme()

const Register = () => {

    const form = useRef()
    const checkBtn = useRef()

    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [username, setUsername] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [successful, setSuccessful] = useState(false)
    const [message, setMessage] = useState('')

    const onChangeFirstname = e => {
        const firstname = e.target.value
        setFirstName(firstname)
    }

    const onChangeLastname = e => {
        const lastname = e.target.value
        setLastName(lastname)
    }

    const onChangeUsername = e => {
        const username = e.target.value
        setUsername(username)
    }

    const onChangeEmail = e => {
        const email = e.target.value
        setEmail(email)
    }

    const onChangePassword = e => {
        const password = e.target.value
        setPassword(password)
    }

    const handleSubmit = e => {
        e.preventDefault()
        setMessage('')
        setSuccessful(false)

        form.current.validateAll()

        if (checkBtn.current.context._errors.length === 0) {
            AuthService.register(firstName, lastName, username, email, password).then(
                response => {
                    setMessage(response.data.message)
                    setSuccessful(true)
                },
                error => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString()

                    setMessage(resMessage)
                    setSuccessful(false)
                }
            )
        }
    }

    return (
        // <>
        //   <h1>Register page</h1>
        // </>
        <ThemeProvider theme={defaultTheme}>
            <Container component='main' maxWidth='xs'>
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}
                >
                    <Avatar sx={{ m: 1, bgColor: 'secondary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component='h1' variant='h5'>
                        Sign up
                    </Typography>
                    <Form onSubmit={handleSubmit} ref={form}>
                        <Box noValidate sx={{ mt: 3 }}>
                            {!successful && (
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        {/* <TextField
                      name='firstName'
                      required
                      fullWidth
                      id='firstName'
                      label='First Name'
                      autoFocus
                    /> */}
                                        <Input
                                            style={{
                                                marginBottom: '3px',
                                                border: '1px solid lightgrey',
                                                height: '45px'
                                            }}
                                            type='text'
                                            className='form-control'
                                            name='fistname'
                                            value={firstName}
                                            onChange={onChangeFirstname}
                                            validations={[required, vfirstname]}
                                            placeholder='First Name..'
                                            autoFocus
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        {/* <TextField
                      required
                      fullWidth
                      id='lastName'
                      label='Last Name'
                      name='lastName'
                      autoComplete='family-name'
                    /> */}
                                        <Input
                                            style={{
                                                marginBottom: '3px',
                                                border: '1px solid lightgrey',
                                                height: '45px'
                                            }}
                                            type='text'
                                            className='form-control'
                                            name='lastname'
                                            value={lastName}
                                            onChange={onChangeLastname}
                                            validations={[required, vlastname]}
                                            placeholder='Last Name...'
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        {/* <TextField
                      required
                      fullWidth
                      id='username'
                      label='Username'
                      name='username'
                    /> */}
                                        <Input
                                            style={{
                                                marginBottom: '3px',
                                                border: '1px solid lightgrey',
                                                height: '45px'
                                            }}
                                            type='text'
                                            className='form-control'
                                            name='username'
                                            value={username}
                                            onChange={onChangeUsername}
                                            validations={[required, vusername]}
                                            placeholder='Username...'
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        {/* <TextField
                      required
                      fullWidth
                      id='email'
                      label='email'
                      name='email'
                      type='email'
                      autoComplete='email'
                    /> */}
                                        <Input
                                            style={{
                                                marginBottom: '3px',
                                                border: '1px solid lightgrey',
                                                height: '45px'
                                            }}
                                            type='email'
                                            className='form-control'
                                            name='email'
                                            value={email}
                                            onChange={onChangeEmail}
                                            validations={[required, validEmail]}
                                            placeholder='Email...'
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        {/* <TextField
                      required
                      fullWidth
                      id='password'
                      label='password'
                      name='password'
                      type='password'
                    /> */}
                                        <Input
                                            style={{
                                                marginBottom: '3px',
                                                border: '1px solid lightgrey',
                                                height: '45px'
                                            }}
                                            type='password'
                                            className='form-control'
                                            name='password'
                                            value={password}
                                            onChange={onChangePassword}
                                            validations={[required, vpassword]}
                                            placeholder='Password..'
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox value='allowExtraEmails' color='primary' />
                                            }
                                            label='I want to receive inspiration, marketing promotions and updates via email.'
                                        />
                                    </Grid>
                                </Grid>
                            )}
                            <Button
                                type='submit'
                                fullWidth
                                variant='contained'
                                sx={{ mt: 3, mb: 2 }}
                            >
                                Sign Up
                            </Button>
                            <Grid container justifyContent='flex-end'>
                                <Grid item>
                                    Already have an account?{" "}
                                    <Link href='/login' variant='body2'>
                                        Sign in
                                    </Link>
                                </Grid>
                            </Grid>
                        </Box>
                        {message && (
                            <div className='form-group'>
                                <div
                                    className={
                                        successful ? 'alert alert-success' : 'alert alert-danger'
                                    }
                                    role='alert'
                                >
                                    {message}
                                </div>
                            </div>
                        )}
                        <CheckButton style={{ display: 'none' }} ref={checkBtn} />
                    </Form>
                </Box>
                <Copyright sx={{ mt: 5 }} />
            </Container>
        </ThemeProvider>
    )
}

export default Register;