import React from 'react';
import PropTypes from 'prop-types';
import Pagination from '@mui/material/Pagination';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles'
import { Button, InputBase } from '@material-ui/core'
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Breadcrumbs from '@mui/material/Breadcrumbs';

import { Link } from "react-router-dom";

import { makeStyles } from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import IconButton from '@mui/material/IconButton'
import AddIcon from '@mui/icons-material/Add'
import SearchIcon from '@material-ui/icons/Search'
import SendIcon from '@mui/icons-material/Send';
import DetailsIcon from '@mui/icons-material/Details';
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import FileDownloadIcon from '@mui/icons-material/FileDownload';

import Collapse from '@mui/material/Collapse';

import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

// import Navbar from '../components/Navbar';
import Navbar from '../components/NewNavbar';
import { Typography } from '@mui/material';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary
}))

// const useStyles = makeStyles(theme => ({
//     table: {
//         minWidth: 650
//     },
//     root: {
//         // padding: '2px 4px',
//         display: 'flex',
//         alignItems: 'center',
//         width: 400
//     },
//     input: {
//         marginLeft: theme.spacing(1),
//         flex: 1
//     },
//     iconButton: {
//         padding: 10
//     },
//     divider: {
//         height: 28,
//         margin: 4
//     }
// }))

// const useStyles = makeStyles({
//     root: {
//         width: '100%',
//         overflowX: 'auto'
//     },
//     table: {
//         width: 'auto'
//     }
// });

const useStyles = makeStyles(theme => ({
    table: {
        minWidth: 'auto'
    },
    root: {
        // padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 400
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1
    },
    iconButton: {
        padding: 10
    },
    divider: {
        height: 28,
        margin: 4
    }
}))

// function createData(name, calories, fat, carbs, protein, detail, action) {
//     return { name, calories, fat, carbs, protein, detail, action };
// }

// const rows = [
//     createData(
//         'Frozen yoghurt',
//         159,
//         6.0,
//         24,
//         4.0,
//         'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
//     ),
//     createData(
//         'Ice cream sandwich',
//         237,
//         9.0,
//         37,
//         4.3,
//         'Maecenas rutrum urna vel lacus viverra, id ultrices dui rutrum'
//     ),
//     createData(
//         'Eclair',
//         262,
//         16.0,
//         24,
//         6.0,
//         'Morbi congue gravida nunc, eu cursus felis vulputate id'
//     ),
//     createData(
//         'Cupcake',
//         305,
//         3.7,
//         67,
//         4.3,
//         'Vestibulum efficitur, ipsum consectetur finibus maximus, ligula dolor vehicula ex, sed viverra nulla mauris id purus'
//     ),
//     createData(
//         'Gingerbread',
//         356,
//         16.0,
//         49,
//         3.9,
//         ' Suspendisse vehicula eu libero eget viverra'
//     )
// ];

// const ExpandableTableRow = ({ children, expandComponent, ...otherProps }) => {
//     const [isExpanded, setIsExpanded] = React.useState(false);

//     return (
//         <>
//             <TableRow {...otherProps}>
//                 <TableCell padding="checkbox">
//                     <IconButton onClick={() => setIsExpanded(!isExpanded)}>
//                         {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
//                     </IconButton>
//                 </TableCell>
//                 {children}
//             </TableRow>
//             {isExpanded && (
//                 <TableRow>
//                     <TableCell padding="checkbox" />
//                     {expandComponent}
//                 </TableRow>
//             )}
//         </>
//     );
// };

function createData(bc_ID, bc_name, instructor_ID, instructor_name, status, join_date, rights, detail) {
    return {
        bc_ID,
        bc_name,
        instructor_ID,
        instructor_name,
        status,
        join_date,
        rights,
        detail,
        
    }
}

function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);

    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    {row.bc_ID}
                </TableCell>
                <TableCell align="center">{row.bc_name}</TableCell>
                <TableCell align="center">{row.instructor_ID}</TableCell>
                <TableCell align="left">{row.instructor_name}</TableCell>
                <TableCell align="center">{row.status}</TableCell>
                <TableCell align="center">{row.join_date}</TableCell>
                <TableCell align="center"><Link to={'/instructors/rights/' + row.rights}>Course Right</Link></TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            {/* <Typography variant="h6" gutterBottom component="div">
                                History
                            </Typography> */}
                            {/* <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Date</TableCell>
                                        <TableCell>Customer</TableCell>
                                        <TableCell align="right">Amount</TableCell>
                                        <TableCell align="right">Total Price ($)</TableCell>
                                        <TableCell align='center'>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {row.history.map((historyRow) => (
                                        <TableRow key={historyRow.date}>
                                            <TableCell component="th" scope="row">
                                                {historyRow.date}
                                            </TableCell>
                                            <TableCell>{historyRow.customerId}</TableCell>
                                            <TableCell align="right">{historyRow.amount}</TableCell>
                                            <TableCell align="right">{Math.round(historyRow.amount * row.price * 100) / 100}</TableCell>
                                            <TableCell align="center">
                                                <Button variant="outlined"><EditIcon />Edit</Button>{' '}
                                                <Button variant="outlined"><DeleteIcon />Delete</Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table> */}
                            <Typography variant="h6" gutterBottom component="div" sx={{ mt: '10px', mb: '15px' }}>
                                Action <br />
                                {/* <p>{row.detail}</p> */}
                                <Grid sx={{ pt: 1, pb: 2 }}>
                                    <Button variant="outlined" style={{ marginRight: '5px' }}><SendIcon style={{ fontSize: '1em', marginRight: '5px' }} />Send Email</Button>{' '}
                                    <Button variant="outlined" style={{ marginRight: '5px' }}><DetailsIcon style={{ fontSize: '1em', marginRight: '5px' }} />View</Button>{' '}
                                    <Button variant="outlined" style={{ marginRight: '5px' }}><EditIcon style={{ fontSize: '1em', marginRight: '5px' }} />Edit</Button>{' '}
                                    <Button variant="outlined" style={{ marginRight: '5px' }}><DeleteIcon style={{ fontSize: '1em', marginRight: '5px' }} />Delete</Button>
                                </Grid>
                            </Typography>

                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    )
}

Row.propTypes = {
    row: PropTypes.shape({
        bc_ID: PropTypes.number.isRequired,
        bc_name: PropTypes.string.isRequired,
        instructor_ID: PropTypes.number.isRequired,
        // history: PropTypes.arrayOf(
        //     PropTypes.shape({
        //         amount: PropTypes.number.isRequired,
        //         customerId: PropTypes.string.isRequired,
        //         date: PropTypes.string.isRequired,
        //     }),
        // ).isRequired,
        instructor_name: PropTypes.string.isRequired,
        status: PropTypes.string.isRequired,
        join_date: PropTypes.string.isRequired,
        rights: PropTypes.number.isRequired,
    }).isRequired,
};

const rows = [
    createData('BC003', 'Syahdan', 10001, 'EDI EFFENDI', 'Active', '2023-11-03', 112, 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'),
    createData('BC003', 'Syahdan', 10013, 'OCTONI IKA SIMBOLON', 'Active', '2023-11-03', 113, 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'),
    createData('BC003', 'Syahdan', 10022, 'AGUSTINUS EKA BUDIMAN', 'InActive', '2023-11-03', 114, 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'),
    createData('BC003', 'Syahdan', 10055, 'IIE MARGARITA CHANDRA', 'InActive', '2023-08-03', 115, 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'),
    createData('BC003', 'Syahdan', 10072, 'ALDREY PERDANA HANSYAH', 'InActive', '2023-08-11', 116, 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'),
]


const defaultTheme = createTheme()

const Instructors = (props) => {

    const { row } = props;
    const [open, setOpen] = React.useState(false);

    const classes = useStyles()

    const [age, setAge] = React.useState('');

    const handleChange = (event: SelectChangeEvent) => {
        setAge(event.target.value);
    };

    // return (
    //     <>
    //     <h1>Instructors page...</h1>
    //     </>
    // )
    return (
        <ThemeProvider theme={defaultTheme}>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />

                <Navbar />
                <Box
                    component='main'
                    sx={{
                        backgroundColor: theme =>
                            theme.palette.mode === 'light'
                                ? theme.palette.grey[100]
                                : theme.palette.grey[900],
                        flexGrow: 1,
                        height: '100vh',
                        overflow: 'auto'
                    }}
                >
                    <Toolbar />
                    <Breadcrumbs aria-label="breadcrumb" sx={{ mt: 2, ml: 2, p: 1 }}>
                        {/* <Link underline="hover" color="inherit" href="/">
                                MUI
                            </Link> */}
                        <Link
                            underline="hover"
                            color="inherit"
                            to="/"
                        >
                            Home
                        </Link>
                        <Typography color="text.primary">Instructors</Typography>
                    </Breadcrumbs>
                    <Container maxWidth='xl' sx={{ mt: 1, mb: 3 }}>
                        {/* <Typography><h6><ChevronRightIcon />{' '}Courses</h6></Typography> */}
                        <Grid container spacing={3}>
                            {/* SECTION #1 */}
                            <Grid item xs={12} md={12} lg={12}>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    <Grid item>
                                        <Button variant="outlined"><AddIcon style={{ fontSize: '1em', marginRight: '5px' }} />Add Data</Button>{' '}
                                        <Button variant="outlined" style={{ marginLeft: '10px' }}><FileDownloadIcon style={{ fontSize: '1em', marginRight: '5px' }} />Export</Button>
                                    </Grid>
                                    <Grid item>
                                        <Paper component='form' className={classes.root} style={{ paddingLeft: '10px' }}>
                                            <InputBase
                                                className={classes.input}
                                                placeholder='Search instructors...'
                                            />
                                            <IconButton
                                                // type='submit'
                                                className={classes.iconButton}
                                                aria-label='search'
                                            >
                                                <SearchIcon />
                                            </IconButton>
                                        </Paper>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={12} lg={12}>
                                <Paper
                                    sx={{
                                        p: 2,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        height: 'auto'
                                    }}
                                >
                                    {/* Content #3 */}

                                    <TableContainer component={Paper}>
                                        <Table aria-label="collapsible table">
                                            <TableHead>
                                                <TableRow>

                                                    <TableCell />
                                                    <TableCell style={{ fontWeight: 'bold' }}>BC ID</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold' }} align="center">BC Name</TableCell>
                                                    <TableCell align="center" style={{ fontWeight: 'bold' }}>Instructor ID</TableCell>
                                                    <TableCell align="center" style={{ fontWeight: 'bold' }}>Instructor Name</TableCell>
                                                    <TableCell align="center" style={{ fontWeight: 'bold' }}>Status</TableCell>
                                                    <TableCell align="center" style={{ fontWeight: 'bold' }}>Join Date</TableCell>
                                                    <TableCell align="center" style={{ fontWeight: 'bold' }}>Rights</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {rows.map((row) => (
                                                    <Row key={row.name} row={row} />
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>

                                </Paper>
                            </Grid>
                        </Grid>

                        <Grid container spacing={3} sx={{ pt: 2 }} direction="row" justifyContent="space-between" alignItems="center">
                            <Grid item>
                                <FormControl sx={{ m: 1, minWidth: 75 }} size="small">
                                    <Select
                                        value={age}
                                        onChange={handleChange}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        <MenuItem value={10}>5</MenuItem>
                                        <MenuItem value={20}>10</MenuItem>
                                        <MenuItem value={30}>15</MenuItem>
                                        <MenuItem value={40}>20</MenuItem>
                                        <MenuItem value={50}>25</MenuItem>
                                    </Select>
                                    {/* <FormHelperText>Without label</FormHelperText> */}
                                </FormControl>
                            </Grid>
                            <Grid item>
                                <Pagination count={3} />
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
            </Box>
        </ThemeProvider>
    )
}

export default Instructors;