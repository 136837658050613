import React from 'react';
import PropTypes from 'prop-types';
import Pagination from '@mui/material/Pagination';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles'
import { Button, InputBase } from '@material-ui/core'
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Breadcrumbs from '@mui/material/Breadcrumbs';

import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import IconButton from '@mui/material/IconButton'
import AddIcon from '@mui/icons-material/Add'
import SearchIcon from '@material-ui/icons/Search'
import SendIcon from '@mui/icons-material/Send';
import DetailsIcon from '@mui/icons-material/Details';
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import PersonIcon from '@mui/icons-material/Person';
import CloseIcon from '@mui/icons-material/Close';

import Collapse from '@mui/material/Collapse';

import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

// import Navbar from '../components/Navbar';
import Navbar from '../components/NewNavbar';
import { Typography } from '@mui/material';

/******* Dialog **********/
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

/*********** List ************/
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

import { blue } from '@mui/material/colors';

import AppBar from '@mui/material/AppBar';
import Divider from '@mui/material/Divider';
import Slide from '@mui/material/Slide';

/********** Image *************/
import templateEmail from '../email_template3.png';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary
}))

const useStyles = makeStyles(theme => ({
    table: {
        minWidth: 'auto'
    },
    root: {
        // padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 400
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1
    },
    iconButton: {
        padding: 10
    },
    divider: {
        height: 28,
        margin: 4
    }
}))

function createData(broadcast_ID, broadcast_name, subject, content, send_to, course_id, created_by, created_at) {
    return {
        broadcast_ID,
        broadcast_name,
        subject,
        content,
        send_to: [
            'adhitya.dwijayantho@binus.edu', 'testing@gmail.com', 'robert.deniro@gmail.com', 'bruce.banner@avengers.com', 'tony.stark@industries.com', 'username@gmail.com', 'user02@gmail.com'
        ],
        course_id,
        created_by,
        created_at,
        cc_to: [
            'adhitya.dwijayantho@binus.ed'
        ]
    }
}

Row.PropTypes = {
    row: PropTypes.shape({
        broadcast_ID: PropTypes.number.isRequired,
        broadcast_name: PropTypes.string.isRequired,
        subject: PropTypes.string.isRequired,
        content: PropTypes.string.isRequired,
        send_to: PropTypes.string.isRequired,
        course_id: PropTypes.number.isRequired,
        created_by: PropTypes.string.isRequired,
        created_at: PropTypes.string.isRequired,
        cc_to: PropTypes.string.isRequired,
    }).isRequired,
};

const rows = [
    createData(1, 'New Course for Python', 'Broadcast for new Python programmer for any member', 'Content', 'Group Email', 'BC001', 'Adhitya Dwijayantho', '2023-07-20'),
    createData(2, 'New Course for Data Science', 'Broadcast for new Data Science for level intermediate', 'Content', 'Group Email', 'BC001', 'Adhitya Dwijayantho', '2023-07-20'),
    createData(3, 'New Java Course for Data Science', 'Broadcast for Data Science for level intermediate', 'Content', 'Group Email', 'BC001', 'Adhitya Dwijayantho', '2023-07-20'),
    createData(4, 'New Java Course for Data Science', 'Broadcast for Data Science for level advanced', 'Content', 'Group Email', 'BC001', 'Adhitya Dwijayantho', '2023-07-20'),
    createData(5, 'New PHP for Data Science', 'Broadcast Data Science for Beginner level', 'Content', 'Group Email', 'BC001', 'Adhitya Dwijayantho', '2023-07-20')
];

const defaultTheme = createTheme()

// const emails = ['username@gmail.com', 'user02@gmail.com'];

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);

    const { onClose } = props;

    const handleClose = () => {
        setOpenDialog(false);
    }

    /********* Dialog **********/
    const [openDialog, setOpenDialog] = React.useState(false);
    const [scroll, setScroll] = React.useState('paper');

    const handleClickOpen = (scrollType) => () => {
        setOpenDialog(true);
        setScroll(scrollType);
    };

    const descriptionElementRef = React.useRef(null);
    React.useEffect(() => {
        if (openDialog) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [openDialog])

    const handleListItemClick = (value) => {
        onClose(value);
    };

    /********* Content FullScreen Dialog ********/
    const [openDialogFS, setOpenDialogFS] = React.useState(false);

    const handleClickOpenDialog = () => {
        setOpenDialogFS(true);
        // alert('fullscreen dialog')
    }

    const handleCloseDialog = () => {
        setOpenDialogFS(false);
    }

    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    {row.broadcast_ID}
                </TableCell>
                <TableCell align='left'>{row.broadcast_name}</TableCell>
                <TableCell align='left'>{row.subject}</TableCell>
                <TableCell align='center'><Button variant="outlined" style={{ borderRadius: '25px' }} size="small" onClick={handleClickOpenDialog}>Content</Button></TableCell>
                <TableCell align='center'><Button variant="outlined" style={{ borderRadius: '25px' }} size="small" onClick={handleClickOpen('paper')}>Group Users</Button></TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Typography variant="h6" gutterBottom component="div">
                                Detail Created
                            </Typography>

                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{ fontWeight: 'bold' }} align='center'>Course ID</TableCell>
                                        <TableCell style={{ fontWeight: 'bold' }} align='center'>Created By</TableCell>
                                        <TableCell style={{ fontWeight: 'bold' }} align='center'>Created At</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell component="th" scope="row" align='center'>
                                            {row.course_id}
                                        </TableCell>
                                        <TableCell>{row.created_by}</TableCell>
                                        <TableCell align="center">{row.created_at}</TableCell>
                                        <TableCell align="center">
                                            <Button variant="outlined"><EditIcon />Edit</Button>{' '}
                                            <Button variant="outlined"><DeleteIcon />Delete</Button>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>

                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>

            {/* Content for Group Email */}
            <Dialog
                open={openDialog}
                onClose={handleClose}
                scroll={scroll}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle id="scroll-dialog-title">Email Group</DialogTitle>
                <DialogContent dividers={scroll === 'paper'}>
                    <DialogContentText
                        id="scroll-dialog-description"
                        ref={descriptionElementRef}
                        tabIndex={-1}
                    >
                        <List sx={{ pt: 0 }}>
                            {row.send_to.map((email) => (
                                <ListItem disableGutters>
                                    <ListItemButton onClick={() => handleListItemClick(email)} key={email}>
                                        <ListItemAvatar>
                                            <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>
                                                <PersonIcon />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText primary={email} />
                                    </ListItemButton>
                                </ListItem>
                            ))}
                            <ListItem disableGutters>
                                <ListItemButton
                                    autoFocus
                                    onClick={() => handleListItemClick('addAccount')}
                                >
                                    <ListItemAvatar>
                                        <Avatar>
                                            <AddIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary="Add account" />
                                </ListItemButton>
                            </ListItem>
                        </List>

                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleClose}>Send</Button>
                </DialogActions>
            </Dialog>

            {/* Dialog for Fullscreen Content */}
            <Dialog
                fullScreen
                open={openDialogFS}
                onClose={handleCloseDialog}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleCloseDialog}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} component="div" style={{fontSize:'16px'}}>
                            Content Email
                        </Typography>
                        <Button autoFocus color="inherit" onClick={handleCloseDialog}>
                            save
                        </Button>
                    </Toolbar>
                </AppBar>
                {/* <List>
                    <ListItem button>
                        <ListItemText primary="Phone ringtone" secondary="Titania" />
                    </ListItem>
                    <Divider />
                    <ListItem button>
                        <ListItemText
                            primary="Default notification ringtone"
                            secondary="Tethys"
                        />
                    </ListItem>
                </List> */}
                {/* <img
                    src="https://images.unsplash.com/photo-1502657877623-f66bf489d236?auto=format&fit=crop&w=800"
                    srcSet="https://images.unsplash.com/photo-1502657877623-f66bf489d236?auto=format&fit=crop&w=800&dpr=2 2x"
                    loading="lazy"
                    alt=""
                /> */}
                <img
                    src={templateEmail}
                    srcSet="https://images.unsplash.com/photo-1502657877623-f66bf489d236?auto=format&fit=crop&w=800&dpr=2 2x"
                    loading="lazy"
                    alt=""
                />


            </Dialog>

        </React.Fragment>
    )
}

const Broadcast = (props) => {

    const { row } = props;
    const classes = useStyles()

    const [age, setAge] = React.useState('');

    const handleChange = (event: SelectChangeEvent) => {
        setAge(event.target.value);
    };

    return (
        <ThemeProvider theme={defaultTheme}>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />

                <Navbar />
                <Box
                    component='main'
                    sx={{
                        backgroundColor: theme =>
                            theme.palette.mode === 'light'
                                ? theme.palette.grey[100]
                                : theme.palette.grey[900],
                        flexGrow: 1,
                        height: '100vh',
                        overflow: 'auto'
                    }}
                >
                    <Toolbar />
                    <Breadcrumbs aria-label="breadcrumb" sx={{ mt: 2, ml: 2, p: 1 }}>
                        <Link
                            underline="hover"
                            color="inherit"
                            to="/"
                        >
                            Home
                        </Link>
                        <Typography color="text.primary">Broadcasts</Typography>
                    </Breadcrumbs>
                    <Container maxWidth='xl' sx={{ mt: 1, mb: 3 }}>
                        {/* <Typography><h6><ChevronRightIcon />{' '}Courses</h6></Typography> */}
                        <Grid container spacing={3}>

                            <Grid item xs={12} md={12} lg={12}>
                                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                                    <Grid item>
                                        <Button variant="outlined">
                                            <AddIcon style={{ fontSize: '1em', marginRight: '5px' }} />Add Data
                                        </Button>
                                        <Button variant="outlined" style={{ marginLeft: '10px' }}><FileDownloadIcon style={{ fontSize: '1em', marginRight: '5px' }} />Export</Button>
                                    </Grid>
                                    <Grid item>
                                        <Paper component='form' className={classes.root} style={{ paddingLeft: '10px' }}>
                                            <InputBase className={classes.input}
                                                placeholder="Search broadcast..." />
                                            <IconButton
                                                className={classes.iconButton} aria-label='search'>
                                                <SearchIcon />
                                            </IconButton>
                                        </Paper>
                                    </Grid>
                                </Grid>

                            </Grid>


                            <Grid item xs={12} md={12} lg={12}>
                                <Paper
                                    sx={{
                                        p: 2,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        height: 'auto'
                                    }}
                                >
                                    {/* Content #3 */}
                                    <TableContainer component={Paper}>
                                        <Table aria-label="collapsible table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell />
                                                    <TableCell style={{ fontWeight: 'bold' }}>ID</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold' }} align='center'>Broadcast Name</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold' }} align='center'>Subject</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold' }} align='center'>Content</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold' }} align='center'>Send To</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {rows.map((row) => (
                                                    <Row key={row.broadcast_name} row={row} />
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Paper>
                            </Grid>
                        </Grid>

                        <Grid container spacing={3} sx={{ pt: 2 }} direction="row" justifyContent="space-between" alignItems="center">
                            <Grid item>
                                <FormControl sx={{ m: 1, minWidth: 75 }} size="small">
                                    <Select
                                        value={age}
                                        onChange={handleChange}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}>
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        <MenuItem value={10}>5</MenuItem>
                                        <MenuItem value={20}>10</MenuItem>
                                        <MenuItem value={30}>15</MenuItem>
                                        <MenuItem value={40}>20</MenuItem>
                                        <MenuItem value={50}>25</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item>
                                <Pagination count={5} />
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
            </Box>
        </ThemeProvider>
    )

}

export default Broadcast;