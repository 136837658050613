import React, { useState, useEffect, forwardRef } from 'react';
import axios from 'axios';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

/*** Import all icons ****/
import InfoIcon from '@mui/icons-material/Info';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import DoneIcon from '@mui/icons-material/Done';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

/**** Add Modal popup */
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

/** Import table */
import {
    GridRowModes,
    DataGrid,
    GridToolbarContainer,
    GridActionsCellItem,
    GridRowEditStopReasons,
    GridToolbarExport
} from '@mui/x-data-grid';
import {
    randomCreatedDate,
    randomTraderName,
    randomId,
    randomArrayItem,
} from '@mui/x-data-grid-generator';

import { Link } from "react-router-dom";

import { makeStyles } from '@material-ui/core/styles';

// import Navbar from '../components/Navbar';
import Navbar from '../components/NewNavbar';
import { Typography } from '@mui/material';

/**** Add Sweetalert */
import Swal from 'sweetalert2'

/**** Services */
import categoryService from '../services/categoryService';

// const columns = [
//     { field: 'id', headerName: 'No', width: 70, headerAlign: 'center', align: 'center' },
//     { field: 'catName', headerName: 'Category', minWidth: 300, flex: 1, headerAlign: 'center' },
//     { field: 'total', headerName: 'Total Course', minWidth: 50, flex: 1, headerAlign: 'center', align: 'center' },
//     {
//         field: 'actions', headerName: 'Actions', minWidth: 200, flex: 1, sortable: false, headerAlign: 'center', align: 'center', sortable: false, hideable: false, disableColumnMenu: true, renderCell: (params) => {
//             return (
//                 <>

//                     <Stack direction="row" spacing={1}>
//                         <Button xs={{ m: 2 }}
//                             onClick={() => editData(params.row.id)}
//                             variant="contained" style={{ borderRadius: '28px' }}
//                         >
//                             Edit
//                         </Button>
//                         <Button
//                             onClick={() => deleteData(params.row.id)}
//                             variant="contained" style={{ borderRadius: '28px' }}
//                         >
//                             Delete
//                         </Button>
//                     </Stack>
//                 </>
//             );
//         }
//     }
// ];

// const rows = [
//     { id: 1, catName: 'short course', total: 10 },
//     { id: 2, catName: 'special class', total: 1 },
//     { id: 3, catName: 'authorized training', total: 13 },
//     { id: 4, catName: 'computer for student', total: 3 },
//     { id: 5, catName: 'in house training', total: 2 },
//     { id: 6, catName: 'design', total: 1 },
//     { id: 7, catName: 'kartu prakerja', total: 5 },
//     { id: 8, catName: 'language', total: 2 },
//     { id: 9, catName: 'microsoft office', total: 3 },
//     { id: 10, catName: 'networking', total: 7 },
//     { id: 11, catName: 'programming', total: 2 },
//     { id: 12, catName: 'sertifikasi', total: 31 }
// ]

const initialRows = [
    { id: 1, courseName: 'PHP', total: 10 },
    { id: 2, courseName: 'Excel', total: 1 },
    { id: 3, courseName: 'Web Programming', total: 13 },
    { id: 4, courseName: 'Business', total: 3 },
    { id: 5, courseName: 'Data Science', total: 2 },
    { id: 6, courseName: 'Python', total: 1 },
    { id: 7, courseName: 'Java', total: 5 },
    { id: 8, courseName: 'Data Analytic', total: 2 },
    { id: 9, courseName: 'Machine Learning', total: 3 },
    { id: 10, courseName: 'Networking', total: 7 },
    { id: 11, courseName: 'Flutter', total: 2 }
]

function EditToolbar(props) {
    const { setRows, setRowModesModel } = props;

    const [fullWidth, setFullWidth] = useState(true);
    const [maxWidth, setMaxWidth] = useState('sm');

    const [open, setOpen] = useState(false);

    const handleClick = () => {
        // alert('add data!')
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    return (
        <>
            <GridToolbarContainer>
                <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
                    Add record
                </Button>
                <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
            </GridToolbarContainer>

            <Dialog
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                open={open}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description">
                <DialogTitle>{"Form Add Course Category"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        This is form for add category
                    </DialogContentText>
                    <Box component="form" sx={{
                        '& > :not(style)': { m: 1 },
                    }}
                        noValidate
                        autocomplete="off">
                        <FormControl fullWidth>
                            <TextField fullWidth id="outlined-basic" label="Course Category Name" variant="outlined" />
                        </FormControl>

                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleClose}>Submit</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary
}))

const useStyles = makeStyles(theme => ({
    table: {
        minWidth: 650
    },
    root: {
        // padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 400
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1
    },
    iconButton: {
        padding: 10
    },
    divider: {
        height: 28,
        margin: 4
    }
}))

const editData = (id) => {
    alert(`edit data ` + id)
}

const deleteData = (id) => {
    alert(`delete data ` + id)
}

const defaultTheme = createTheme({
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: 28,
                },
            },
        },
    },
})

const Category = () => {

    const classes = useStyles()

    // const onButtonClick = (e, id) => {
    //     alert(id)
    // }

    // const [rows, setRows] = useState(initialRows); // using dummy data
    const [rows, setRows] = useState([]);
    const [rowModesModel, setRowModesModel] = useState({});

    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(5);

    const handleRowEditStop = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }
    };

    const handleEditClick = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };

    const handleSaveClick = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };

    const handleDeleteClick = (id) => () => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                setRows(rows.filter((row) => row.id !== id));
                Swal.fire(
                    'Deleted!',
                    'Your file has been deleted.',
                    'success'
                )
            }
        })
    };

    const handleCancelClick = (id) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });

        const editedRow = rows.find((row) => row.id === id);
        if (editedRow.isNew) {
            setRows(rows.filter((row) => row.id !== id));
        }
    };

    const processRowUpdate = (newRow) => {
        const updatedRow = { ...newRow, isNew: false };
        setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
        return updatedRow;
    };

    const handleRowModesModelChange = (newRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };

    const columns = [
        { field: 'id', headerName: 'No', width: 70, headerAlign: 'center', align: 'center' },
        { field: 'courseName', headerName: 'Category', minWidth: 300, flex: 1, headerAlign: 'center', editable: true },
        { field: 'total', headerName: 'Total Course', minWidth: 50, flex: 1, headerAlign: 'center', align: 'center' },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 100,
            cellClassName: 'actions',
            getActions: ({ id, courseName }) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<SaveIcon />}
                            label="Save"
                            sx={{
                                color: 'primary.main',
                            }}
                            onClick={handleSaveClick(id)}
                        />,
                        <GridActionsCellItem
                            icon={<CancelIcon />}
                            label="Cancel"
                            className="textPrimary"
                            onClick={handleCancelClick(id)}
                            color="inherit"
                        />,
                    ];
                }

                return [
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Edit"
                        className="textPrimary"
                        onClick={handleEditClick(id)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Delete"
                        onClick={handleDeleteClick(id)}
                        color="inherit"
                    />,
                ];
            },
        },
    ];


    useEffect(() => {
        getCategory()
    }, [])

    const getCategory = async () => {
        await categoryService.getAllCategory()
        .then(res => {
            console.log(res.result);
            setRows(res.result);
        })
        .catch(err => console.log(err.message))
    }

    return (
        <ThemeProvider theme={defaultTheme}>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />

                <Navbar />
                <Box
                    component='main'
                    sx={{
                        backgroundColor: theme =>
                            theme.palette.mode === 'light'
                                ? theme.palette.grey[100]
                                : theme.palette.grey[900],
                        flexGrow: 1,
                        height: '100vh',
                        overflow: 'auto'
                    }}
                >
                    <Toolbar />
                    {/* <Container maxWidth='lg' sx={{ mt: 4, mb: 4 }}>
                        <h1>Dashboard page</h1>
                    </Container> */}
                    {/* <div role="presentation"> */}
                    <Breadcrumbs aria-label="breadcrumb" sx={{ mt: 2, ml: 2, p: 1 }}>
                        {/* <Link underline="hover" color="inherit" href="/">
                                MUI
                            </Link> */}
                        <Link
                            underline="hover"
                            color="inherit"
                            to="/"
                        >
                            Home
                        </Link>
                        <Typography color="text.primary">Course Category</Typography>
                    </Breadcrumbs>
                    {/* </div> */}

                    <Container maxWidth='xl' sx={{ mt: 1, mb: 3 }}>
                        {/* <Typography><h6><ChevronRightIcon />{' '}Categories</h6></Typography> */}



                        <Grid container spacing={3}>
                            {/* SECTION #1 */}
                            {/* <Grid item xs={12} md={6} lg={6}>
                                <Paper
                                    sx={{
                                        p: 1,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        // height: 240
                                    }}
                                >
                                    Content #2

                                </Paper>
                            </Grid> */}
                            <Grid item xs={12} md={12} lg={12}>
                                <Paper
                                    sx={{
                                        // p: 1, 
                                        display: 'flex',
                                        flexDirection: 'column',
                                        height: 'auto'
                                    }}
                                >
                                    {/* Content #3 */}
                                    <div style={{ height: 480, width: '100%' }}>
                                        <DataGrid
                                            rows={rows}
                                            columns={columns}
                                            editMode="row"
                                            rowModesModel={rowModesModel}
                                            onRowModesModelChange={handleRowModesModelChange}
                                            onRowEditStop={handleRowEditStop}
                                            processRowUpdate={processRowUpdate}
                                            slots={{
                                                toolbar: EditToolbar,
                                            }}
                                            slotProps={{
                                                toolbar: { setRows, setRowModesModel },
                                            }}

                                            initialState={{
                                                pagination: {
                                                    paginationModel: {
                                                        page: page,
                                                        pageSize: limit
                                                    }
                                                },
                                                columns: {
                                                    columnVisibilityModel: {
                                                        rating: false,
                                                        version: false
                                                    }
                                                }
                                            }}
                                            pageSizeOptions={[5, 10, 25, 50, 100]}
                                        />
                                    </div>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
            </Box>
        </ThemeProvider>
    )
}

export default Category;