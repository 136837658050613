import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles'
import { Button, InputBase } from '@material-ui/core'
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

/**************************************
 * For pdf viewer
 **************************************/
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { toolbarPlugin, ToolbarSlot } from '@react-pdf-viewer/toolbar';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

import demoFile from '../Python_Programming.pdf';

/******* List icons ********/
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import HomeIcon from '@mui/icons-material/Home';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import GrainIcon from '@mui/icons-material/Grain';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import InventoryIcon from '@mui/icons-material/Inventory';

// import Link from '@mui/material/Link';
import { Link } from "react-router-dom";
import Breadcrumbs from '@mui/material/Breadcrumbs';

const drawerWidth = 240;

const styles = theme => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
});

const data = [{
    id: 1,
    course_name: 'Session 1 - Introduction for Python',
    type: 'pdf'
}, {
    id: 2,
    course_name: 'Session 2 - Python for Beginner',
    type: 'pdf'
},
{
    id: 3,
    course_name: 'Session 3 - Python for Data Science',
    type: 'pdf'
},
{
    id: 4,
    course_name: 'Session 4 - Python for Practical',
    type: 'pdf'
}, {
    id: 5,
    course_name: 'Session 5 - Python for Student',
    type: 'pdf'
}, {
    id: 6,
    course_name: 'Session 6 - Python for Student 2',
    type: 'pdf'
}, {
    id: 7,
    course_name: 'Session 7 - Python for Student 3',
    type: 'pdf'
}];


const DetailPackage = () => {

    // const { classes } = props;

    const [courses, setCourses] = useState([]);
    const [currentCourse, setCurrentCourse] = useState(null);
    const [currentIndex, setCurrentIndex] = useState(-1);

    useEffect(() => {
        retrieveCourses();
    }, [])


    const retrieveCourses = () => {
        setCourses(data);
        console.log(data);
    }

    const setActiveCourse = (course, index) => {
        setCurrentCourse(course);
        setCurrentIndex(index);
    }

    const renderToolbar = (Toolbar: (props: ToolbarProps) => ReactElement) => (
        <Toolbar>
            {(slots: ToolbarSlot) => {
                const { ZoomOut } = slots;
                return (
                    <div
                        style={{
                            alignItems: "center",
                            display: "flex"
                        }}
                    >
                        <div style={{ padding: "0px 2px" }}>
                            <ZoomOut>
                                {(props) => (
                                    <button
                                        style={{
                                            backgroundColor: "#357edd",
                                            border: "none",
                                            borderRadius: "4px",
                                            color: "#ffffff",
                                            cursor: "pointer",
                                            padding: "8px"
                                        }}
                                        onClick={props.onClick}
                                    >
                                        Zoom out
                                    </button>
                                )}
                            </ZoomOut>
                        </div>

                    </div>
                );
            }}
        </Toolbar>
    );

    const defaultLayoutPluginInstance = defaultLayoutPlugin();


    return (
        // <>
        //     <h1>Detail Package page...</h1>
        // </>
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position='fixed' sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                <Toolbar>


                    <Breadcrumbs aria-label="breadcrumb" color="white">
                        <Link
                            underline="hover"
                            sx={{ display: 'flex', alignItems: 'center' }}
                            style={{ fontColor: 'white' }}
                            to="/"
                        >
                            <DashboardIcon sx={{ mr: 0.5 }} fontSize="inherit" style={{ color: 'white' }} />
                            <span style={{ color: 'white' }}>Dashboard</span>
                        </Link>
                        <Link
                            underline="hover"
                            sx={{ display: 'flex', alignItems: 'center' }}
                            style={{ fontColor: 'white' }}
                            to="/package"
                        >
                            <InventoryIcon sx={{ mr: 0.5 }} fontSize="inherit" style={{ color: 'white' }} />
                            <span style={{ color: 'white' }}>Package</span>
                        </Link>
                        <Typography
                            sx={{ display: 'flex', alignItems: 'center' }}
                            color="white"
                        >
                            <GrainIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                            {/* Package Detail Name */}
                            CEH: Certified Ethical Hacker
                        </Typography>
                    </Breadcrumbs>

                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
                }}>
                <Toolbar />
                <Box sx={{ overflow: 'auto' }}>

                    <List>
                        {courses.map((row, index) => (
                            <ListItem style={{ paddingLeft: '0px', paddingRight: '1px' }}>
                                <ListItemButton onClick={() => setActiveCourse(row, index)} key={index}>
                                    <ListItemIcon>
                                        {(row.type) === 'pdf' ? <PictureAsPdfIcon /> : <MailIcon style={{ padding: 0 }} />}
                                    </ListItemIcon>
                                    <ListItemText
                                        primaryTypographyProps={{ fontSize: '13px' }}
                                        primary={row.course_name}
                                        secondaryTypographyProps={{ fontSize: '11px' }}
                                        secondary="Duration 3 minutes" />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                    {/* <Divider />
                    <List>
                        {['All mail', 'Trash', 'Spam'].map((text, index) => (
                            <ListItem key={text} disablePadding>
                                <ListItemButton>
                                    <ListItemIcon>
                                        {index % 2 === 0 ? <PictureAsPdfIcon /> : <MailIcon />}
                                    </ListItemIcon>
                                    <ListItemText primary={text} secondary="Duration : 3 minutes" />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List> */}
                </Box>
            </Drawer>
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                <Toolbar />
                <Typography paragraph>
                    {currentCourse ? (
                        // <div style={{ marginLeft: '10px', width: '100%' }}>
                        <Grid item xs={12} md={12} lg={12}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Grid item>
                                    <h6 style={{paddingTop:'5px'}}>{currentCourse.course_name}</h6>
                                </Grid>
                                <Grid item>
                                    <Button variant="outlined" size="small" style={{ marginRight: '10px' }}>Edit</Button>
                                    <Button variant="outlined" size="small">Delete</Button>
                                </Grid>
                            </Grid>

                            <div style={{ width: '100%', marginTop: '8px' }}>
                                <Typography paragraph>
                                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
                                        <div style={{ height: "720px" }}>
                                            <Viewer fileUrl={demoFile} plugins={[defaultLayoutPluginInstance]} />
                                        </div>
                                    </Worker>
                                </Typography>
                            </div>
                        </Grid>
                        // </div>
                    ) : (
                        <div>
                            <p>Please click on Course...</p>
                        </div>
                    )}
                </Typography>
                {/* <Typography paragraph>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                    tempor incididunt ut labore et dolore magna aliqua. Rhoncus dolor purus non
                    enim praesent elementum facilisis leo vel. Risus at ultrices mi tempus
                    imperdiet. Semper risus in hendrerit gravida rutrum quisque non tellus.
                    Convallis convallis tellus id interdum velit laoreet id donec ultrices.
                    Odio morbi quis commodo odio aenean sed adipiscing. Amet nisl suscipit
                    adipiscing bibendum est ultricies integer quis. Cursus euismod quis viverra
                    nibh cras. Metus vulputate eu scelerisque felis imperdiet proin fermentum
                    leo. Mauris commodo quis imperdiet massa tincidunt. Cras tincidunt lobortis
                    feugiat vivamus at augue. At augue eget arcu dictum varius duis at
                    consectetur lorem. Velit sed ullamcorper morbi tincidunt. Lorem donec massa
                    sapien faucibus et molestie ac.
                </Typography>
                <Typography paragraph>
                    Consequat mauris nunc congue nisi vitae suscipit. Fringilla est ullamcorper
                    eget nulla facilisi etiam dignissim diam. Pulvinar elementum integer enim
                    neque volutpat ac tincidunt. Ornare suspendisse sed nisi lacus sed viverra
                    tellus. Purus sit amet volutpat consequat mauris. Elementum eu facilisis
                    sed odio morbi. Euismod lacinia at quis risus sed vulputate odio. Morbi
                    tincidunt ornare massa eget egestas purus viverra accumsan in. In hendrerit
                    gravida rutrum quisque non tellus orci ac. Pellentesque nec nam aliquam sem
                    et tortor. Habitant morbi tristique senectus et. Adipiscing elit duis
                    tristique sollicitudin nibh sit. Ornare aenean euismod elementum nisi quis
                    eleifend. Commodo viverra maecenas accumsan lacus vel facilisis. Nulla
                    posuere sollicitudin aliquam ultrices sagittis orci a.
                </Typography>
                <Typography paragraph>
                    Consequat mauris nunc congue nisi vitae suscipit. Fringilla est ullamcorper
                    eget nulla facilisi etiam dignissim diam. Pulvinar elementum integer enim
                    neque volutpat ac tincidunt. Ornare suspendisse sed nisi lacus sed viverra
                    tellus. Purus sit amet volutpat consequat mauris. Elementum eu facilisis
                    sed odio morbi. Euismod lacinia at quis risus sed vulputate odio. Morbi
                    tincidunt ornare massa eget egestas purus viverra accumsan in. In hendrerit
                    gravida rutrum quisque non tellus orci ac. Pellentesque nec nam aliquam sem
                    et tortor. Habitant morbi tristique senectus et. Adipiscing elit duis
                    tristique sollicitudin nibh sit. Ornare aenean euismod elementum nisi quis
                    eleifend. Commodo viverra maecenas accumsan lacus vel facilisis. Nulla
                    posuere sollicitudin aliquam ultrices sagittis orci a.
                </Typography>
                <Typography paragraph>
                    Consequat mauris nunc congue nisi vitae suscipit. Fringilla est ullamcorper
                    eget nulla facilisi etiam dignissim diam. Pulvinar elementum integer enim
                    neque volutpat ac tincidunt. Ornare suspendisse sed nisi lacus sed viverra
                    tellus. Purus sit amet volutpat consequat mauris. Elementum eu facilisis
                    sed odio morbi. Euismod lacinia at quis risus sed vulputate odio. Morbi
                    tincidunt ornare massa eget egestas purus viverra accumsan in. In hendrerit
                    gravida rutrum quisque non tellus orci ac. Pellentesque nec nam aliquam sem
                    et tortor. Habitant morbi tristique senectus et. Adipiscing elit duis
                    tristique sollicitudin nibh sit. Ornare aenean euismod elementum nisi quis
                    eleifend. Commodo viverra maecenas accumsan lacus vel facilisis. Nulla
                    posuere sollicitudin aliquam ultrices sagittis orci a.
                </Typography> */}
            </Box>
        </Box>
    )
}

// DetailPackage.propTypes = {
//     classes: PropTypes.object.isRequired,
// };

// export default withStyles(styles)(DetailPackage);
export default DetailPackage