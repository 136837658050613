import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import Pagination from '@mui/material/Pagination';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles'
import { withStyles } from "@material-ui/core/styles";
import { Button, InputBase } from '@material-ui/core'
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Breadcrumbs from '@mui/material/Breadcrumbs';

import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';

import ListSubheader from "@mui/material/ListSubheader";
import List from "@mui/material/List";
import ListItem from '@mui/material/ListItem';
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from '@mui/material/ListItemAvatar';
import IconButton from '@mui/material/IconButton';
import Collapse from "@mui/material/Collapse";
import Avatar from '@mui/material/Avatar';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import Navbar from '../components/NewNavbar';
import { Typography } from '@mui/material';

/*** Import 'icons *****/
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import AddIcon from '@mui/icons-material/Add';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import RemoveIcon from '@mui/icons-material/Remove';
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import MovieCreationOutlinedIcon from '@mui/icons-material/MovieCreationOutlined';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import ImageIcon from '@mui/icons-material/Image';
import WorkIcon from '@mui/icons-material/Work';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import MovieIcon from '@mui/icons-material/Movie';

import Badge from '@mui/material/Badge';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary
}))

const useStyles = makeStyles(theme => ({
    table: {
        minWidth: 'auto'
    },
    root: {
        // padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        // width: 400
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1
    },
    iconButton: {
        padding: 10
    },
    divider: {
        height: 28,
        margin: 4
    }
}))

const data = [{
    id: 1,
    folder: 'RPP',
    link: 'http://testing.com'
}, {
    id: 2,
    course: 'Form Tenaga Pengajar',
    link: 'http://testing.com'
}, {
    id: 3,
    course: 'Live & Dummy Access',
    link: 'http://testing.com'
}, {
    id: 4,
    course: 'Bank Soal',
    link: 'http://testing.com'
}, {
    id: 5,
    course: 'Modul Ajar',
    link: 'http://testing.com'
}]

const defaultTheme = createTheme()

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}>
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired
}

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

const DetailPrakerja = () => {

    /**** Modal Tab ****/
    const [value, setValue] = useState(0)

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }


    return (
        <>
            {/* <h1>Detail Prakerja</h1> */}
            <ThemeProvider theme={defaultTheme}>
                <Box sx={{ display: 'flex' }}>
                    <CssBaseline />
                    <Navbar />
                    <Box
                        component='main'
                        sx={{
                            backgroundColor: theme =>
                                theme.palette.mode === 'light'
                                    ? theme.palette.grey[100]
                                    : theme.palette.grey[900],
                            flexGrow: 1,
                            height: '100vh',
                            overflow: 'auto'
                        }}>
                        <Toolbar />
                        <Breadcrumbs aria-label="breadcrumb" sx={{ mt: 2, ml: 2, p: 1 }}>
                            <Link
                                underline="hover"
                                color="inherit"
                                to="/">
                                Home
                            </Link>
                            <Link
                                underline="hover"
                                color="inherit"
                                to="/prakerja">
                                Prakerja Course
                            </Link>
                            <Typography color="text.primary">Detail Prakerja</Typography>
                        </Breadcrumbs>

                        <Container maxWidth='xl' sx={{ mt: 1, mb: 3 }}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={12} lg={12}>
                                    <Paper
                                        sx={{
                                            p: 1
                                        }}>
                                        {/* Content #1 */}

                                        <Box sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex' }}>
                                            <Tabs
                                                style={{ minHeight: '480px', minWidth: '280px', textAlign: 'left' }}
                                                orientation="vertical"
                                                variant="scrollable"
                                                value={value}
                                                onChange={handleChange}
                                                aria-label="Vertical tabs example"
                                                sx={{ borderRight: 1, borderColor: 'divider' }}>
                                                <Tab label="RPP" {...a11yProps(0)} />
                                                <Tab label="Form Tenaga Pengajar" {...a11yProps(1)} />
                                                <Tab label="Live & Dummy Access" {...a11yProps(2)} />
                                                <Tab label="Bank Soal" {...a11yProps(3)} />
                                                <Tab label="Modul Ajar" {...a11yProps(4)} />
                                                <Tab label="Other Folder" {...a11yProps(5)} />
                                                <Tab label="Item Seven" {...a11yProps(6)} />
                                            </Tabs>
                                            <TabPanel value={value} index={0}>
                                                {/* Item One Only */}

                                                <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                                                    <ListItem>
                                                        <ListItemAvatar>
                                                            <Avatar>
                                                                <TextSnippetIcon />
                                                            </Avatar>
                                                        </ListItemAvatar>
                                                        <ListItemText primary="Rancangan RPP rev.1" secondary="Jan 9, 2014" />
                                                    </ListItem>
                                                    <ListItem>
                                                        <ListItemAvatar>
                                                            <Avatar>
                                                                <TextSnippetIcon />
                                                            </Avatar>
                                                        </ListItemAvatar>
                                                        <ListItemText primary="Rancangan RPP revisi.2" secondary="Jan 7, 2014" />
                                                    </ListItem>
                                                    <ListItem>
                                                        <ListItemAvatar>
                                                            <Avatar>
                                                                <TextSnippetIcon />
                                                            </Avatar>
                                                        </ListItemAvatar>
                                                        <ListItemText primary="Rancangan RPP rev.3" secondary="July 20, 2014" />
                                                    </ListItem>
                                                </List>

                                            </TabPanel>
                                            <TabPanel value={value} index={1}>
                                                No item available
                                            </TabPanel>
                                            <TabPanel value={value} index={2}>
                                                <List sx={{ width: '100%', maxWidth: '720', bgcolor: 'background.paper' }}>
                                                    <ListItem>
                                                        <ListItemAvatar>
                                                            <Avatar>
                                                                <MovieIcon />
                                                            </Avatar>
                                                        </ListItemAvatar>
                                                        <ListItemText primary="First Course - Video Pembelajaran Sesi 1" secondary="Jan 9, 2014" />
                                                    </ListItem>
                                                    <ListItem>
                                                        <ListItemAvatar>
                                                            <Avatar>
                                                                <MovieIcon />
                                                            </Avatar>
                                                        </ListItemAvatar>
                                                        <ListItemText primary="Second Course - Video Pembelajaran Sesi 2" secondary="Jan 9, 2014" />
                                                    </ListItem>
                                                    <ListItem>
                                                        <ListItemAvatar>
                                                            <Avatar>
                                                                <MovieIcon />
                                                            </Avatar>
                                                        </ListItemAvatar>
                                                        <ListItemText primary="Third Course - Video Pembelajaran Sesi 3" secondary="Jan 9, 2014" />
                                                    </ListItem>
                                                    <ListItem>
                                                        <ListItemAvatar>
                                                            <Avatar>
                                                                <MovieIcon />
                                                            </Avatar>
                                                        </ListItemAvatar>
                                                        <ListItemText primary="Fourth Course - Video Pembelajaran Sesi 4" secondary="Jan 9, 2014" />
                                                    </ListItem>
                                                    <ListItem>
                                                        <ListItemAvatar>
                                                            <Avatar>
                                                                <MovieIcon />
                                                            </Avatar>
                                                        </ListItemAvatar>
                                                        <ListItemText primary="Fifth Course - Video Pembelajaran Sesi 5" secondary="Jan 9, 2014" />
                                                    </ListItem>
                                                </List>
                                            </TabPanel>
                                            <TabPanel value={value} index={3}>
                                                <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                                                    <ListItem>
                                                        <ListItemAvatar>
                                                            <Avatar>
                                                                <TextSnippetIcon />
                                                            </Avatar>
                                                        </ListItemAvatar>
                                                        <ListItemText primary="Soal Pre-Test" secondary="Jan 9, 2014" />
                                                    </ListItem>
                                                    <ListItem>
                                                        <ListItemAvatar>
                                                            <Avatar>
                                                                <TextSnippetIcon />
                                                            </Avatar>
                                                        </ListItemAvatar>
                                                        <ListItemText primary="Soal Post-Test" secondary="Jan 7, 2014" />
                                                    </ListItem>
                                                </List>
                                            </TabPanel>
                                            <TabPanel value={value} index={4}>
                                                <List sx={{ width: '100%', maxWidth: 640, bgcolor: 'background.paper' }}>
                                                    <ListItem>
                                                        <ListItemAvatar>
                                                            <Avatar>
                                                                <TextSnippetIcon />
                                                            </Avatar>
                                                        </ListItemAvatar>
                                                        <ListItemText primary="Materi Session 1.doc" secondary="Jan 9, 2014" />
                                                    </ListItem>
                                                    <ListItem>
                                                        <ListItemAvatar>
                                                            <Avatar>
                                                                <TextSnippetIcon />
                                                            </Avatar>
                                                        </ListItemAvatar>
                                                        <ListItemText primary="Materi Session 2.doc" secondary="Jan 7, 2014" />
                                                    </ListItem>
                                                </List>
                                            </TabPanel>
                                            <TabPanel value={value} index={5}>
                                                Item Six
                                            </TabPanel>
                                            {/* <TabPanel value={value} index={6}>
                                                Item Seven
                                            </TabPanel> */}
                                        </Box>
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Container>
                    </Box>
                </Box>
            </ThemeProvider>
        </>
    )
}

export default DetailPrakerja