import * as React from 'react';
// import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { Link } from "react-router-dom";

// function preventDefault(event) {
//     event.preventDefault();
// }

export default function instructorTotal() {
    return (
        <>
            <h5 style={{ textAlign: 'center', marginTop: '20px' }}>Total Instructors</h5>
            <Typography component='p' variant='h3' style={{ paddingTop: '10px', textAlign: 'center' }}>
                12
            </Typography>
            <Typography color='text.secondary' sx={{ flex: 1 }} style={{ paddingTop: '5px', textAlign: 'center' }}>
                on 3 Aug, 2023
            </Typography>
            <div style={{ textAlign: 'center', position: 'bottom' }}>
                <Link color="primary" to="/instructors">
                    View all
                </Link>
            </div>
        </>
    )
}