import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import Pagination from '@mui/material/Pagination';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles'
import { withStyles } from "@material-ui/core/styles";
import { Button, InputBase } from '@material-ui/core'
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Breadcrumbs from '@mui/material/Breadcrumbs';

import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';

import ListSubheader from "@mui/material/ListSubheader";
import List from "@mui/material/List";
import ListItem from '@mui/material/ListItem';
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import IconButton from '@mui/material/IconButton';
import Collapse from "@mui/material/Collapse";

import Navbar from '../components/NewNavbar';
import { Typography } from '@mui/material';

/*** Import 'icons *****/
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import AddIcon from '@mui/icons-material/Add';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import RemoveIcon from '@mui/icons-material/Remove';
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import MovieCreationOutlinedIcon from '@mui/icons-material/MovieCreationOutlined';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import Badge from '@mui/material/Badge';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary
}))

const useStyles = makeStyles(theme => ({
    table: {
        minWidth: 'auto'
    },
    root: {
        // padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        // width: 400
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1
    },
    iconButton: {
        padding: 10
    },
    divider: {
        height: 28,
        margin: 4
    }
}))

const data = [{
    id: 1,
    course: "Pengenalan Excel Dashboard dan Reports",
    content: [{
        contentId: 1,
        name: "Pengertian Dashboard",
        update: "27 Sep 2023",
        status: 'development',
        progress: '33%'
    },
    {
        contentId: 1,
        name: "Membuat model",
        update: "21 Sep 2023",
        status: 'development',
        progress: '30%'
    }]
}, {
    id: 2,
    course: "Komponen dasar pada Dashboard",
    content: [{
        contentId: 1,
        name: "Menggunakan Data",
        update: "27 Sep 2023",
        status: 'Not Started',
        progress: '0%'
    }, {
        contentId: 2,
        name: "Memahami Sparklines",
        update: "27 Sep 2023",
        status: 'Not Started',
        progress: '0%'
    }, {
        contentId: 3,
        name: "Memulai Visualisasi Data",
        update: "27 Sep 2023",
        status: 'Not Started',
        progress: '0%'
    }, {
        contentId: 4,
        name: "Memahami Pivot Table",
        update: "27 Sep 2023",
        status: 'Not Started',
        progress: '0%'
    }]
}, {
    id: 3,
    course: "Membuat Dashboard (Lanjutan)",
    content: [{
        contentId: 1,
        name: "Membuat trending data menggunakan charts",
        update: "27 Sep 2023",
        status: 'Not Started',
        progress: '0%'
    }
        , {
        contentId: 2,
        name: "Grouping data",
        update: "27 Sep 2023",
        status: 'Not Started',
        progress: '0%'
    },
    {
        contentId: 3,
        name: "Membandingkan perencanaan dan target",
        update: "27 Sep 2023",
        status: 'Not Started',
        progress: '0%'
    }]
}, {
    id: 4,
    course: "Membuat Laporan",
    content: [{
        contentId: 1,
        name: "Dashboard Reporting",
        update: "27 Sep 2023",
        status: 'Not Started',
        progress: '0%'
    },
    {
        contentId: 2,
        name: "Menambahkan User Interaktif pada Interface",
        update: "27 Sep 2023",
        status: 'Not Started',
        progress: '0%'
    }]
}, {
    id: 5,
    course: 'Excel vs External Data',
    content: [{
        contentId: 1,
        name: "Membuat Dashboard dan Laporan menggunakan data eksternal",
        update: "27 Sep 2023",
        status: 'Not Started',
        progress: '0%'
    },
    {
        contentId: 2,
        name: "Sharing Workbook",
        update: "27 Sep 2023",
        status: 'Not Started',
        progress: '0%'
    }]
}, {
    id: 6,
    course: 'Prinsip desain pada Charts',
    content: []
}]

const LeftListItemText = withStyles({
    root: {
        textAlign: "left"
    }
})(ListItemText);

const RightListItemText = withStyles({
    root: {
        textAlign: "right"
    }
})(ListItemText);


const defaultTheme = createTheme()

const MyCollapsibleComponent = ({ course, content }) => {
    const [open, setOpen] = useState(false);

    const handleClick = () => {
        setOpen(!open);
    };

    return (
        <>
            <ListItemButton onClick={() => handleClick()}>
                <ChevronRightIcon style={{ fontSize: '14px', marginRight: '14px' }} />
                <ListItemText primary={course} />
                {open ? <RemoveIcon style={{ fontSize: '14px', marginRight: '14px' }} /> : <AddIcon style={{ fontSize: '14px', marginRight: '14px' }} />}
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
                {/* <List component="div" disablePadding>
                    <ListItemButton sx={{ pl: 4 }}>
                        <ListItemText primary={content} />
                    </ListItemButton>
                </List> */}

                {content.map(item => (

                    <List disablePadding style={{ marginLeft: '25px' }}>
                        {/* <ListItem disablePadding secondaryAction={
                            <IconButton edge="end" aria-label="delete" style={{ marginRight: '3px'}}>
                                <EditIcon />
                            </IconButton>
                        }> */}
                        <ListItem disablePadding >
                            <ListItemText>
                                <MovieCreationOutlinedIcon style={{ fontSize: '14px', marginRight: '25px' }} />
                            </ListItemText>
                            {/* <ListItemText primary="Someshit inside Collapse" alignItme /> */}
                            <ListItem>
                                <LeftListItemText primary={item.name} secondary={item.update} style={{minWidth:'540px'}}/>
                            </ListItem>
                            <ListItem style={{ marginLeft: '10px' }}>
                                {/* <LeftListItemText primary="development" /> */}
                                <RightListItemText primary={<Badge badgeContent={item.status} color="info" style={{ width: '100%' }} />} />
                            </ListItem>
                            <ListItem style={{ right: 0 }}>
                                <RightListItemText primary={item.progress} />
                            </ListItem>
                            <ListItem>
                                <RightListItemText><IconButton edge="end" aria-label="delete" style={{ marginRight: '0px' }}>
                                    <EditIcon />
                                </IconButton></RightListItemText>
                            </ListItem>
                        </ListItem>
                    </List>

                ))}

            </Collapse>
        </>
    );
};


const DetailRegular = () => {

    const classes = useStyles()

    const [result, setResult] = useState([]);

    useEffect(() => {
        setResult(data);
        console.log(result)
    })


    return (
        // <>
        // <h1>This is Detail Regular</h1>
        // </>

        <ThemeProvider theme={defaultTheme}>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <Navbar />
                <Box component='main'
                    sx={{
                        backgroundColor: theme =>
                            theme.palette.mode === 'light'
                                ? theme.palette.grey[100]
                                : theme.palette.grey[900],
                        flexGrow: 1,
                        height: '100vh',
                        overflow: 'auto'
                    }}>
                    <Toolbar />
                    <Breadcrumbs aria-label="breadcrumb" sx={{ mt: 2, ml: 2, p: 1 }}>
                        {/* <Link underline="hover" color="inherit" href="/">
                                MUI
                            </Link> */}
                        <Link
                            underline="hover"
                            color="inherit"
                            to="/"
                        >
                            Home
                        </Link>
                        {/* <Typography color="text.primary">Course</Typography> */}
                        <Link
                            underline="hover"
                            color="inherit"
                            to="/regular"
                        >
                            Regular Course
                        </Link>
                        <Typography color="text.primary">Detail Regular</Typography>
                    </Breadcrumbs>
                    <Container maxWidth="xl" sx={{ mt: 1, mb: 3 }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={12} lg={12}>
                                <Paper
                                    sx={{
                                        p: 2,
                                        display: 'flex',
                                        flexDirection: 'column'
                                    }}
                                >
                                    {/* Content #1 */}

                                    <List
                                        sx={{ width: "100%", bgcolor: "background.paper" }}
                                        component="nav"
                                        aria-labelledby="nested-list-subheader"
                                        subheader={
                                            <ListSubheader component="div" id="nested-list-subheader" style={{ paddingTop: '12px', paddingBottom: '5px' }}>
                                                <h6><LocalLibraryIcon style={{ fontSize: '14px', marginRight: '14px' }} />Meningkatkan Penggunaan Fungsi Excel dalam Membangun Mekanisme Pelaporan yang Lebih Baik: Panduan Teknis dan Analitis</h6>
                                            </ListSubheader>
                                        }
                                    >

                                        {result.map(row => (
                                            <MyCollapsibleComponent course={row.course} content={row.content} />
                                        ))}
                                        {/* <MyCollapsibleComponent course="Course 1" content="Research Paper" />
            <MyCollapsibleComponent course="Course 2" content="Texting" />
            <MyCollapsibleComponent course="Course 3" content="Exams" /> */}
                                    </List>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
            </Box>
        </ThemeProvider>
    )
}

export default DetailRegular;