import React from 'react';

const History = () => {
    return (
        <>
        <h1>History page...</h1>
        </>
    )
}

export default History;