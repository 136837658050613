import axios from 'axios';

// const API_URL = 'http://localhost:8080/api/auth/'
import API_URL from '../config/baseURL';

const register = (firstname, lastname, username, email, password) => {

    var data = {
        firstname: firstname,
        lastname: lastname,
        username: username,
        email: email,
        password: password
    }
    console.log(data);

    // return axios
    // .post(API_URL + "signup", {
    //     firstname,
    //     lastname,
    //     username,
    //     email,
    //     password
    // })

}

const login = (username, password) => {
    // console.log(username)
    // console.log(password)
    console.log(API_URL)

    return axios
    .post(API_URL + 'auth/signin', {
        username,
        password
    })
    .then(response => {
        if (response.data.accessToken) {
            localStorage.setItem('user', JSON.stringify(response.data))
        }

        return response.data
    })
}

const logout = () => {
    return localStorage.removeItem('user')
}

const getCurrentUser = () => {
    return JSON.parse(localStorage.getItem('user'))
}

const AuthService = {
    register,
    login,
    logout,
    getCurrentUser
}

export default AuthService