import React, { useRef, useLayoutEffect } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);

export default function userTotal() {
    const chart = useRef(null);

    useLayoutEffect(() => {

        am4core.addLicense("ch-custom-attribution");


        /*****************************
         *  Pie Chart 
         *****************************/

        var chart = am4core.create("chartdiv", am4charts.PieChart);

        // Add data
        chart.data = [{
            "users": "Binusian",
            "totals": 800,
            "color": am4core.color("#ED1C24")
        }, {
            "users": "Non-Binusian",
            "totals": 200,
            "color": am4core.color("#235789")
        }];

        // chart.innerRadius = am4core.percent(100)
        chart.radius = am4core.percent(48)
        // chart.paddingTop = 0;
        // chart.paddingRight = 0;
        // chart.paddingBottom = 0;
        // chart.paddingLeft = 0;

        // Add and configure Series
        var pieSeries = chart.series.push(new am4charts.PieSeries());
        pieSeries.dataFields.value = "totals";
        pieSeries.dataFields.category = "users";
        pieSeries.slices.template.propertyFields.fill = "color";

        chart.legend = new am4charts.Legend();

        /************** end pie chart ***************/

    }, []);

    return (
        <>
            <div id="chartdiv" style={{ width: "100%", height: "440px" }}></div>
        </>
    );
}