import React, { useState, useEffect, forwardRef } from 'react';
import PropTypes from 'prop-types';
import Pagination from '@mui/material/Pagination';
import { styled, createTheme, ThemeProvider, useTheme } from '@mui/material/styles'
import { Button, InputBase } from '@material-ui/core'
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Chip from '@mui/material/Chip';

/**** Add Modal popup */
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';

/**** Add Sweetalert */
import Swal from 'sweetalert2'

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

import { Link } from "react-router-dom";

import { makeStyles } from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';

/*** Import icons */
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import IconButton from '@mui/material/IconButton'
import AddIcon from '@mui/icons-material/Add'
import SearchIcon from '@material-ui/icons/Search'
import SendIcon from '@mui/icons-material/Send';
import DetailsIcon from '@mui/icons-material/Details';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete'
import CancelIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit'
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FolderIcon from '@mui/icons-material/Folder';
import InfoIcon from '@mui/icons-material/Info';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import RemoveIcon from '@mui/icons-material/Remove';
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import MovieCreationOutlinedIcon from '@mui/icons-material/MovieCreationOutlined';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';

import Collapse from '@mui/material/Collapse';

import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

// import Navbar from '../components/Navbar';
import Navbar from '../components/NewNavbar';
import { Typography } from '@mui/material';

/** Import table */
import {
    GridRowModes,
    DataGrid,
    GridToolbar,
    GridToolbarContainer,
    GridActionsCellItem,
    GridRowEditStopReasons,
    GridToolbarExport
} from '@mui/x-data-grid';

// const columns = [
//     {
//         field: 'id', headerName: 'No', width: 50, headerAlign: 'center', align: 'center'
//     },
//     {
//         field: 'courseName', headerName: 'Course Name', minWidth: 300, flex: 1, headerAlign: 'center', renderCell: (params) => {
//             const title = params.row.courseName
//             var string = '';
//             if (title.length > 20) {
//                 string = title.substring(0, 21) + "..."
//             } else {
//                 string = title
//             }

//             return (
//                 <>
//                     <Link to={"/prakerja/" + params.row.id}>
//                         <Tooltip arrow title={params.row.courseName}>
//                             <Button xs={{ m: 2 }} variant="contained" style={{ borderRadius: '28px' }}>
//                                 {string}
//                             </Button>
//                         </Tooltip>
//                     </Link>
//                 </>
//             );
//         }
//     },
//     { field: 'categoryName', headerName: 'Category', minWidth: 150, flex: 1, headerAlign: 'center', align: 'center' },
//     { field: 'rating', headerName: 'Rating', headerAlign: 'center', align: 'center' },
//     { field: 'version', headerName: 'Version', headerAlign: 'center', align: 'center' },
//     // { field: 'name_file', headerName: 'Name File', headerAign: 'center', align: 'center' },
//     { field: 'ext', headerName: 'Extension', headerAlign: 'center', align: 'center' },
//     { field: 'size', headerName: 'Size' },
//     { field: 'author', headerName: 'Instructor', minWidth: 150 },
//     { field: 'type', headerName: 'Type', minWidth: 80 },
//     { field: 'total', headerName: 'Total Task', minWidth: 10, headerAlign: 'center', align: 'center' },
//     { field: 'notStarted', headerName: 'Not Started', minWidth: 10, headerAlign: 'center', align: 'center' },
//     { field: 'development', headerName: 'Development', minWidth: 10, headerAlign: 'center', align: 'center' },
//     { field: 'done', headerName: 'Done', minWidth: 10, headerAlign: 'center', align: 'center' },
//     {
//         field: 'actions', headerName: 'Actions', minWidth: 180, flex: 1, sortable: false, hideable: false, disableColumnMenu: true, headerAlign: 'center', align: 'center', renderCell: (params) => {
//             return (
//                 <>
//                     <Stack direction="row" spacing={1}>
//                         {/* <Button xs={{ m: 2 }}
//                             onClick={() => editData(params.row.id)}
//                             variant="contained" style={{ borderRadius: '28px' }}
//                         >

//                         </Button> */}
//                         <IconButton xs={{ m: 2 }}
//                             onClick={() => viewData(params.row.id)}>
//                             <InfoIcon />
//                         </IconButton>

//                         {/* <Button xs={{ m: 2 }}
//                             onClick={() => editData(params.row.id)}
//                             variant="contained" style={{ borderRadius: '28px' }}
//                         >
//                             Edit
//                         </Button> */}
//                         <IconButton xs={{ m: 2 }}
//                             onClick={() => editData(params.row.id)}>
//                             <BorderColorIcon />
//                         </IconButton>
//                         {/* <Button
//                             onClick={() => deleteData(params.row.id)}
//                             variant="contained" style={{ borderRadius: '28px' }}
//                         >
//                             Delete
//                         </Button> */}
//                         <IconButton xs={{ m: 2 }}
//                             onClick={() => deleteData(params.row.id)}
//                             variant="contained" style={{ borderRadius: '28px' }}>
//                             <DeleteIcon />
//                         </IconButton>
//                     </Stack>
//                 </>
//             );
//         }
//     }

// ]

// const rows = [
//     { id: 1, courseName: 'Teknik Membuat Website Statis dan Dinamis', categoryName: 'Web Programming', rating: 5, version: 'v.2', name_file: 'python_for_ds', ext: '.pdf', size: '2MB', author: 'Rudolfo', type: 'Regular', total: 14, notStarted: 11, development: 3, done: 0 },
//     { id: 2, courseName: 'Mengoptimalkan Peluang Bisnis: Membekali Diri dengan Pengetahuan, Keterampilan, dan Pemahaman Pasar yang Realistis', categoryName: 'Business', rating: 4.8, version: 'v.1', name_file: 'aws_certificate_vol.1', ext: '.pdf', size: '11MB', author: 'AWS', type: 'Regular', total: 11, notStarted: 10, development: 1, done: 0 },
//     { id: 3, courseName: 'Panduan Terpadu Praktik dan Metodologi Agile untuk Keberhasilan Manajemen Proyek', categoryName: 'Excel', rating: 4.2, version: '', name_file: 'cisco_certificate_vol.2', ext: '.pdf', size: '22MB', author: 'CISCO', type: 'Regular', total: 18, notStarted: 17, development: 1, done: 0 }
// ]

const initialRows = [
    { id: 1, courseName: 'Teknik Membuat Website Statis dan Dinamis', categoryName: 'Web Programming', rating: 5, version: 'v.2', name_file: 'python_for_ds', ext: '.pdf', size: '2MB', author: 'Rudolfo', type: 'Prakerja', total: 14, notStarted: 11, development: 3, done: 0 },
    { id: 2, courseName: 'Mengoptimalkan Peluang Bisnis: Membekali Diri dengan Pengetahuan, Keterampilan, dan Pemahaman Pasar yang Realistis', categoryName: 'Business', rating: 4.8, version: 'v.1', name_file: 'aws_certificate_vol.1', ext: '.pdf', size: '11MB', author: 'AWS', type: 'Prakerja', total: 11, notStarted: 10, development: 1, done: 0 },
    { id: 3, courseName: 'Panduan Terpadu Praktik dan Metodologi Agile untuk Keberhasilan Manajemen Proyek', categoryName: 'Excel', rating: 4.2, version: '', name_file: 'cisco_certificate_vol.2', ext: '.pdf', size: '22MB', author: 'CISCO', type: 'Prakerja', total: 18, notStarted: 17, development: 1, done: 0 }
]

function EditToolbar(props) {
    const { setRows, setRowModesModel } = props;

    const [open, setOpen] = useState(false);

    const theme = useTheme();
    const [personName, setPersonName] = useState([]);
    const [type, setType] = useState([])

    const handleClick = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const handleChangeSelectCategory = (event) => {
        const {
            target: { value },
        } = event;
        setPersonName(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleChangeSelectType = (event) => {
        const {
            target: { value },
        } = event;
        setType(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };


    return (
        <>
            <GridToolbarContainer>
                <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
                    Add record
                </Button>
                <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
            </GridToolbarContainer>

            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"Form Add Course Prakerja"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        This is form add prakerja course
                        <Box component="form" sx={{
                            '& > :not(style)': { m: 1 },
                        }}
                            noValidate
                            autocomplete="off">
                            <FormControl fullWidth>
                                <TextField fullWidth id="outlined-basic" label="Course Name" variant="outlined" />
                            </FormControl>
                            <FormControl fullWidth>
                                <InputLabel id="demo-multiple-chip-label">Category</InputLabel>
                                <Select
                                    labelId="demo-multiple-chip-label"
                                    id="demo-multiple-chip"
                                    multiple
                                    value={personName}
                                    onChange={handleChangeSelectCategory}
                                    input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                                    renderValue={(selected) => (
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                            {selected.map((value) => (
                                                <Chip key={value} label={value} />
                                            ))}
                                        </Box>
                                    )}
                                    MenuProps={MenuProps}
                                >
                                    {categories.map((name) => (
                                        <MenuItem
                                            key={name}
                                            value={name}
                                            style={getStyles(name, personName, theme)}
                                        >
                                            {name}
                                        </MenuItem>
                                    ))}
                                </Select>

                            </FormControl>
                            <FormControl fullWidth>
                                <InputLabel id="demo-multiple-chip-label">Type</InputLabel>
                                <Select
                                    labelId="demo-multiple-chip-label"
                                    id="demo-multiple-chip"
                                    multiple
                                    value={type}
                                    onChange={handleChangeSelectType}
                                    input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                                    renderValue={(selected) => (
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                            {selected.map((value) => (
                                                <Chip key={value} label={value} />
                                            ))}
                                        </Box>
                                    )}
                                    MenuProps={MenuProps}
                                >
                                    {types.map((name) => (
                                        <MenuItem
                                            key={name}
                                            value={name}
                                            style={getStyles2(name, type, theme)}
                                        >
                                            {name}
                                        </MenuItem>
                                    ))}
                                </Select>

                            </FormControl>
                        </Box>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleClose}>Submit</Button>
                </DialogActions>

            </Dialog>


        </>
    )

}

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary
}))

const useStyles = makeStyles(theme => ({
    table: {
        minWidth: 'auto'
    },
    root: {
        // padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 400
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1
    },
    iconButton: {
        padding: 10
    },
    divider: {
        height: 28,
        margin: 4
    }
}))

const viewData = (id) => {
    alert(`view detail data ` + id)
}

const editData = (id) => {
    alert(`edit data ` + id)
}

const deleteData = (id) => {
    // alert(`delete data ` + id)
    Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
        if (result.isConfirmed) {
            Swal.fire(
                'Deleted!',
                'Your file has been deleted.',
                'success'
            )
        }
    })
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const categories = [
    'Excel',
    'Web Programming',
    'Business',
    'Cyber Security',
    'Data Science',
    'Python',
];

const types = [
    'Regular',
    'Prakerja',
    'Inhouse'
];

function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

function getStyles2(name, type, theme) {
    return {
        fontWeight:
            type.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

const defaultTheme = createTheme()

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}>
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired
}

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}


const prakerjaCourse = () => {

    const classes = useStyles()

    const [rows, setRows] = useState(initialRows);
    const [rowModesModel, setRowModesModel] = useState({});

    // const { data } = props;
    const [message, setMessage] = useState("")
    const [datas, setDatas] = useState([])
    const [open, setOpen] = useState(false);

    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(5);

    const [openDetail, setOpenDetail] = useState(false);
    const [fullWidth, setFullWidth] = useState(true);
    const [maxWidth, setMaxWidth] = useState('lg');

    // const theme = useTheme();
    // const [personName, setPersonName] = useState([]);
    // const [type, setType] = useState([]);

    const handleClickOpen = () => {
        // setOpenDetail(true);
        setOpen(true)
    }

    const handleClose = () => {
        // setOpenDetail(false)
        setOpen(false)
    }

    /**** Modal Tab ****/
    const [value, setValue] = useState(0)

    const handleRowEditStop = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }
    };

    const handleEditClick = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };

    const handleSaveClick = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };

    const handleDeleteClick = (id) => () => {

        // setRows(rows.filter((row) => row.id !== id));

        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                setRows(rows.filter((row) => row.id !== id));
                Swal.fire(
                    'Deleted!',
                    'Your file has been deleted.',
                    'success'
                )
            }
        })

    };

    const handleCancelClick = (id) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });

        const editedRow = rows.find((row) => row.id === id);
        if (editedRow.isNew) {
            setRows(rows.filter((row) => row.id !== id));
        }
    };

    const processRowUpdate = (newRow) => {
        const updatedRow = { ...newRow, isNew: false };
        setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
        return updatedRow;
    };

    const handleRowModesModelChange = (newRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };

    const columns = [
        {
            field: 'id', headerName: 'No', width: 50, headerAlign: 'center', align: 'center'
        },
        // {
        //     field: 'courseName', headerName: 'Course Name', minWidth: 300, flex: 1, headerAlign: 'center', renderCell: (params) => {
        //         const title = params.row.courseName
        //         var string = '';
        //         if (title.length > 20) {
        //             string = title.substring(0, 21) + "..."
        //         } else {
        //             string = title
        //         }

        //         return (
        //             <>
        //                 <Link to={"/prakerja/" + params.row.id}>
        //                     <Tooltip arrow title={params.row.courseName}>
        //                         <Button xs={{ m: 2 }} variant="contained" style={{ borderRadius: '28px' }}>
        //                             {string}
        //                         </Button>
        //                     </Tooltip>
        //                 </Link>
        //             </>
        //         );
        //     }
        // },
        {
            field: 'courseName', headerName: 'Course Name', minWidth: 320, flex: 1, headerAlign: 'center', editable: true, renderCell: (params) => {

                const title = params.row.courseName
                console.log(title);
                var string = '';
                if (title.length > 40) {
                    string = title.substring(0, 41) + "...";
                } else {
                    string = title
                }

                return (
                    <>
                        <Tooltip arrow title={params.row.courseName}>
                            {string}
                        </Tooltip>
                    </>
                )
            }
        },
        {
            field: 'categoryName', headerName: 'Category', minWidth: 150, flex: 1, headerAlign: 'center', align: 'center', editable: true, type: 'singleSelect', valueOptions: [
                'Excel',
                'Web Programming',
                'Business',
                'Cyber Security',
                'Data Science',
                'Python',
            ]
        },
        { field: 'rating', headerName: 'Rating', headerAlign: 'center', align: 'center' },
        { field: 'version', headerName: 'Version', headerAlign: 'center', align: 'center' },
        // { field: 'name_file', headerName: 'Name File', headerAign: 'center', align: 'center' },
        { field: 'ext', headerName: 'Extension', headerAlign: 'center', align: 'center' },
        { field: 'size', headerName: 'Size' },
        { field: 'author', headerName: 'Instructor', minWidth: 150 },
        { field: 'type', headerName: 'Type', minWidth: 80, headerAlign:'center', align:'center' },
        { field: 'total', headerName: 'Total Task', minWidth: 10, headerAlign: 'center', align: 'center' },
        { field: 'notStarted', headerName: 'Not Started', minWidth: 10, headerAlign: 'center', align: 'center' },
        { field: 'development', headerName: 'Development', minWidth: 10, headerAlign: 'center', align: 'center' },
        { field: 'done', headerName: 'Done', minWidth: 10, headerAlign: 'center', align: 'center' },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 100,
            cellClassName: 'actions',
            getActions: ({ id, courseName }) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<SaveIcon />}
                            label="Save"
                            sx={{
                                color: 'primary.main',
                            }}
                            onClick={handleSaveClick(id)}
                        />,
                        <GridActionsCellItem
                            icon={<CancelIcon />}
                            label="Cancel"
                            className="textPrimary"
                            onClick={handleCancelClick(id)}
                            color="inherit"
                        />,
                    ];
                }

                return [
                    <Link to={"/prakerja/" + id}
                    >
                        {/* <Tooltip arrow title={params.row.courseName}>
                            <Button xs={{ m: 2 }} variant="contained" style={{ borderRadius: '28px' }}>
                                {string}
                            </Button>
                        </Tooltip> */}
                        {/* {params.row.courseName} */}
                        <InfoIcon />
                    </Link>,
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Edit"
                        className="textPrimary"
                        onClick={handleEditClick(id)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Delete"
                        onClick={handleDeleteClick(id)}
                        color="inherit"
                    />,
                ];
            },
        },

    ]


    // const handleChange = (event, newValue) => {
    //     setValue(newValue)
    // }

    // const handleChangeSelectCategory = (event) => {
    //     const {
    //         target: { value },
    //     } = event;
    //     setPersonName(
    //         // On autofill we get a stringified value.
    //         typeof value === 'string' ? value.split(',') : value,
    //     );
    // };

    // const handleChangeSelectType = (event) => {
    //     const {
    //         target: { value },
    //     } = event;
    //     setType(
    //         // On autofill we get a stringified value.
    //         typeof value === 'string' ? value.split(',') : value,
    //     );
    // };

    // useEffect(() => {
    //     console.log(data[0].message)
    //     console.log(data[0].result)
    // }, [])

    return (
        <>
            {/* <h1>Prakerja Course</h1> */}
            <ThemeProvider theme={defaultTheme}>
                <Box sx={{ display: 'flex' }}>
                    <CssBaseline />
                    <Navbar />
                    <Box
                        component='main'
                        sx={{
                            backgroundColor: theme =>
                                theme.palette.mode === 'light'
                                    ? theme.palette.grey[100]
                                    : theme.palette.grey[900],
                            flexGrow: 1,
                            height: '100vh',
                            overflow: 'auto'
                        }}>
                        <Toolbar />
                        <Breadcrumbs aria-label="breadcrumb" sx={{ mt: 2, ml: 2, p: 1 }}>
                            <Link
                                underline="hover"
                                color="inherit"
                                to="/"
                            >Home</Link>
                            <Typography color="text.primary">Prakerja Course</Typography>
                        </Breadcrumbs>

                        {/* <Button variant="outlined" onClick={handleClickOpen}>
                            Slide in Alert dialog
                        </Button> */}

                        {/* <Dialog
                            fullWidth={fullWidth}
                            maxWidth={maxWidth}
                            open={openDetail}
                            TransitionComponent={Transition}
                            keepMounted
                            onClose={handleClose}
                            aria-describedby='alert-dialog-slide-description'
                        >
                            <DialogTitle>
                                <h6>
                                    <LocalLibraryIcon style={{ fontSize: '14px', marginRight: '14px' }} />Teknik Membuat Website Statis dan Dinamis
                                </h6>
                            </DialogTitle>
                            <DialogContent> */}
                        {/* Let Google help apps determine location. This means sending anonymous
                                location data to Google, even when no apps are running. */}

                        {/* <Box sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: '222' }}>
                                    <Tabs
                                        orientation="vertical"
                                        variant="scrollable"
                                        value={value}
                                        onChange={handleChange}
                                        aria-label="Vertical tabs example"
                                        sx={{ borderRight: 1, borderColor: 'divider' }}>
                                        <Tab label="Item One" {...a11yProps(0)} />
                                        <Tab label="Item Two" {...a11yProps(1)} />
                                        <Tab label="Item Three" {...a11yProps(2)} />
                                        <Tab label="Item Four" {...a11yProps(3)} />
                                        <Tab label="Item Five" {...a11yProps(4)} />
                                        <Tab label="Item Six" {...a11yProps(5)} />
                                        <Tab label="Item Seven" {...a11yProps(6)} />
                                    </Tabs>
                                    <TabPanel value={value} index={0}>
                                        Item One Only
                                    </TabPanel>
                                    <TabPanel value={value} index={1}>
                                        Item Two
                                    </TabPanel>
                                    <TabPanel value={value} index={2}>
                                        Item Three
                                    </TabPanel>
                                    <TabPanel value={value} index={3}>
                                        Item Four
                                    </TabPanel>
                                    <TabPanel value={value} index={4}>
                                        Item Five
                                    </TabPanel>
                                    <TabPanel value={value} index={5}>
                                        Item Six
                                    </TabPanel>
                                    <TabPanel value={value} index={6}>
                                        Item Seven
                                    </TabPanel>
                                </Box>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose}>Disagree</Button>
                                <Button onClick={handleClose}>Agree</Button>
                            </DialogActions>
                        </Dialog> */}

                        <Container maxWidth='xl' sx={{ mt: 1, mb: 3 }}>
                            <Grid container spacing={3}>
                                {/* <Grid item xs={12} md={12} lg={12}>
                                    <Paper
                                        sx={{
                                            p: 1
                                        }}>
                                        <Button variant="outlined" onClick={handleClickOpen}><AddIcon />{' '}Add Data</Button>
                                    </Paper>
                                </Grid> */}
                                <Grid item xs={12} md={12} lg={12}>
                                    <Paper
                                        sx={{
                                            // p: 2,
                                            display: 'flex',
                                            flexDirection: 'column'
                                        }}>
                                        <div style={{ height: 430, width: '100%' }}>
                                            <DataGrid
                                                rows={rows}
                                                columns={columns}
                                                editMode="row"
                                                rowModesModel={rowModesModel}
                                                onRowModesModelChange={handleRowModesModelChange}
                                                onRowEditStop={handleRowEditStop}
                                                processRowUpdate={processRowUpdate}
                                                slots={{
                                                    toolbar: EditToolbar,
                                                }}
                                                slotProps={{
                                                    toolbar: { setRows, setRowModesModel },
                                                }}
                                                initialState={{
                                                    pagination: {
                                                        paginationModel: { page: page, pageSize: limit },
                                                    },
                                                    columns: {
                                                        columnVisibilityModel: {
                                                            ext: false,
                                                            size: false,
                                                            rating: false,
                                                            version: false,
                                                            author: false
                                                        }
                                                    }
                                                }}
                                                pageSizeOptions={[5, 10, 15, 20, 25]}
                                            />
                                        </div>
                                    </Paper>
                                </Grid>

                            </Grid>
                        </Container>
                    </Box>
                </Box>

                {/* <Dialog
                    open={open}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle>{"Form Add Course Prakerja"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            This is form add prakerja course
                            <Box component="form" sx={{
                                '& > :not(style)': { m: 1 },
                            }}
                                noValidate
                                autocomplete="off">
                                <FormControl fullWidth>
                                    <TextField fullWidth id="outlined-basic" label="Course Name" variant="outlined" />
                                </FormControl>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-multiple-chip-label">Category</InputLabel>
                                    <Select
                                        labelId="demo-multiple-chip-label"
                                        id="demo-multiple-chip"
                                        multiple
                                        value={personName}
                                        onChange={handleChangeSelectCategory}
                                        input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                                        renderValue={(selected) => (
                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                {selected.map((value) => (
                                                    <Chip key={value} label={value} />
                                                ))}
                                            </Box>
                                        )}
                                        MenuProps={MenuProps}
                                    >
                                        {categories.map((name) => (
                                            <MenuItem
                                                key={name}
                                                value={name}
                                                style={getStyles(name, personName, theme)}
                                            >
                                                {name}
                                            </MenuItem>
                                        ))}
                                    </Select>

                                </FormControl>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-multiple-chip-label">Type</InputLabel>
                                    <Select
                                        labelId="demo-multiple-chip-label"
                                        id="demo-multiple-chip"
                                        multiple
                                        value={type}
                                        onChange={handleChangeSelectType}
                                        input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                                        renderValue={(selected) => (
                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                {selected.map((value) => (
                                                    <Chip key={value} label={value} />
                                                ))}
                                            </Box>
                                        )}
                                        MenuProps={MenuProps}
                                    >
                                        {types.map((name) => (
                                            <MenuItem
                                                key={name}
                                                value={name}
                                                style={getStyles2(name, type, theme)}
                                            >
                                                {name}
                                            </MenuItem>
                                        ))}
                                    </Select>

                                </FormControl>
                            </Box>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button onClick={handleClose}>Submit</Button>
                    </DialogActions>

                </Dialog> */}

            </ThemeProvider >
        </>
    )
}

export default prakerjaCourse;