import React from 'react';

import { Routes, Route, Link } from 'react-router-dom';

import Login from './pages/Login';
import Register from './pages/Register';
import Dashboard from './pages/Dashboard';

// import Courses from './pages/Courses';

import Courses from './pages/AllCourse';
import Type from './pages/Type';
import Category from './pages/Category';
import Users from './pages/Users';
import Members from './pages/Members';
import Broadcast from './pages/Broadcast';
import Settings from './pages/Settings';
import Instructors from './pages/Instructors';
import Location from './pages/Location';
import DetailCourse from './pages/DetailCourse';
import Package from './pages/Package';
import History from './pages/History';
import DetailPackage from './pages/DetailPackage';
import CourseRight from './pages/CourseRight';
import Dashboard2 from './pages/Dashboard2';
import RegularCourse from './pages/RegularCourse';

/** Alternative */
// import RegularCourse from './pages/RegularCourseNew';

import PrakerjaCourse from './pages/PrakerjaCourse';
import DetailRegular from './pages/DetailRegular';
import DetailPrakerja from './pages/DetailPrakerja';

export default function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/dashboard2" element={<Dashboard2 />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/courses" element={<Courses />} />
        {/* <Route path="/allCourse" element={<Courses />} /> */}
        <Route path="/regular" element={<RegularCourse />} />
        <Route path="/regular/:id" element={<DetailRegular />} />
        <Route path="/prakerja" element={<PrakerjaCourse />} />
        <Route path="/prakerja/:id" element={<DetailPrakerja />} />
        <Route path="/courses/:id" element={<DetailCourse />} />
        <Route path="/category" element={<Category />} />
        <Route path="/type" element={<Type />} />
        <Route path="/members" element={<Members />} />
        <Route path="/users" element={<Users />} />
        <Route path="/instructors" element={<Instructors />} />
        <Route path="/instructors/rights/:id" element={<CourseRight />} />
        <Route path="/broadcast" element={<Broadcast />} />
        <Route path="/location" element={<Location />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/package" element={<Package />} />
        <Route path="/package/:id" element={<DetailPackage />} />
        <Route path="/history" element={<History />} />
      </Routes>
    </div>
  )
}