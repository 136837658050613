import React from 'react';
import PropTypes from 'prop-types';
import Pagination from '@mui/material/Pagination';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles'
import { Button, InputBase } from '@material-ui/core'
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Breadcrumbs from '@mui/material/Breadcrumbs';

import { Link, Navigate } from "react-router-dom";

import { makeStyles } from '@material-ui/core/styles';

/****** Import tables *********/
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

/****** Import all icons ******/
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import IconButton from '@mui/material/IconButton'
import AddIcon from '@mui/icons-material/Add'
import SearchIcon from '@material-ui/icons/Search'
import SendIcon from '@mui/icons-material/Send';
import DetailsIcon from '@mui/icons-material/Details';
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import FileDownloadIcon from '@mui/icons-material/FileDownload';

import Collapse from '@mui/material/Collapse';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

// import Navbar from '../components/Navbar';
import Navbar from '../components/NewNavbar';
import { Typography } from '@mui/material';

/******* import day picker *******/
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary
}))

const useStyles = makeStyles(theme => ({
    table: {
        minWidth: 650
    },
    root: {
        // padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 200
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1
    },
    iconButton: {
        padding: 10
    },
    divider: {
        height: 28,
        margin: 4
    }
}))


const rows = [{
    no: 1,
    prid: '2022002291',
    package_real_name: 'CEH: Certified Ethical Hacker',
    package_name: 'CEH: Certified Ethical Hacker',
    shift: 'PM3',
    start_date: '12/Jul/2023',
    end_date: '25/Aug/2022',
    status: 'OK',
    binusian: 3,
    non_binusian: 2,
    total_students: 5
},
{
    no: 2,
    prid: '2022002836',
    package_real_name: 'SAP Business One',
    package_name: 'SAP Business One',
    shift: 'PM3',
    start_date: '27/Jul/2023',
    end_date: '12/Sep/2022',
    status: 'OK',
    binusian: 0,
    non_binusian: 0,
    total_students: 0
},
{
    no: 3,
    prid: '2022003117',
    package_real_name: 'SAP Business One',
    package_name: 'SAP Business One',
    shift: 'AM1',
    start_date: '15/Oct/2022',
    end_date: '17/Dec/2022',
    status: 'OK',
    binusian: 2,
    non_binusian: 5,
    total_students: 7
},
{
    no: 4,
    prid: '2022002291',
    package_real_name: 'CEH: Certified Ethical Hacker',
    package_name: 'CEH: Certified Ethical Hacker',
    shift: 'PM3',
    start_date: '12/Jul/2023',
    end_date: '25/Aug/2022',
    status: 'OK',
    binusian: 3,
    non_binusian: 2,
    total_students: 5
},
{
    no: 5,
    prid: '2022002291',
    package_real_name: 'CEH: Certified Ethical Hacker',
    package_name: 'CEH: Certified Ethical Hacker',
    shift: 'PM3',
    start_date: '12/Jul/2023',
    end_date: '25/Aug/2022',
    status: 'OK',
    binusian: 3,
    non_binusian: 2,
    total_students: 5
},
{
    no: 6,
    prid: '2022002291',
    package_real_name: 'CEH: Certified Ethical Hacker',
    package_name: 'CEH: Certified Ethical Hacker',
    shift: 'PM3',
    start_date: '12/Jul/2023',
    end_date: '25/Aug/2022',
    status: 'OK',
    binusian: 3,
    non_binusian: 2,
    total_students: 5
}]

const defaultTheme = createTheme()

function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);

    const viewData = (id) => {
        alert(`View data ` + id);
    }

    const editData = (id) => {
        alert(`Edit data ` + id);
    }

    const deleteData = (id) => {
        alert(`Delete data ` + id);
    }

    return (
        <>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                {/* <TableCell component="th" scope="row" style={{width:'3px'}}>
                    {row.no}
                </TableCell> */}
                <TableCell><Link to={'/package/' + row.prid}>{row.prid}</Link></TableCell>
                <TableCell style={{ width: '200px' }}>{row.package_real_name}</TableCell>
                <TableCell style={{ width: '200px' }}>{row.package_name}</TableCell>
                <TableCell>{row.shift}</TableCell>
                <TableCell>{row.start_date}</TableCell>
                <TableCell>{row.end_date}</TableCell>
                <TableCell align='center'>{row.status}</TableCell>
                <TableCell align='center'>{row.binusian}</TableCell>
                <TableCell align='center'>{row.non_binusian}</TableCell>
                <TableCell align='center'>{row.total_students}</TableCell>

            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            {/* <Typography variant="h6" gutterBottom component="div">
                                History
                            </Typography> */}
                            {/* <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Date</TableCell>
                                        <TableCell>Customer</TableCell>
                                        <TableCell align="right">Amount</TableCell>
                                        <TableCell align="right">Total Price ($)</TableCell>
                                        <TableCell align='center'>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {row.history.map((historyRow) => (
                                        <TableRow key={historyRow.date}>
                                            <TableCell component="th" scope="row">
                                                {historyRow.date}
                                            </TableCell>
                                            <TableCell>{historyRow.customerId}</TableCell>
                                            <TableCell align="right">{historyRow.amount}</TableCell>
                                            <TableCell align="right">{Math.round(historyRow.amount * row.price * 100) / 100}</TableCell>
                                            <TableCell align="center">
                                                <Button variant="outlined"><EditIcon />Edit</Button>{' '}
                                                <Button variant="outlined"><DeleteIcon />Delete</Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table> */}
                            <Typography variant="h6" gutterBottom component="div" sx={{ mt: '10px', mb: '15px' }}>
                                Action <br />
                                {/* <p>{row.detail}</p> */}
                                <Grid sx={{ pt: 1, pb: 2 }}>
                                    <Button variant="outlined" style={{ marginRight: '5px' }} onClick={() => viewData(row.no)}><DetailsIcon style={{ fontSize: '1em', marginRight: '5px' }} />View</Button>{' '}
                                    <Button variant="outlined" style={{ marginRight: '5px' }} onClick={() => editData(row.no)}><EditIcon style={{ fontSize: '1em', marginRight: '5px' }} />Edit</Button>{' '}
                                    <Button variant="outlined" style={{ marginRight: '5px' }} onClick={() => deleteData(row.no)}><DeleteIcon style={{ fontSize: '1em', marginRight: '5px' }} />Delete</Button>
                                </Grid>
                            </Typography>

                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    )
}

const Package = (props) => {

    const { row } = props;
    const [open, setOpen] = React.useState(false);

    const classes = useStyles()

    const [age, setAge] = React.useState('');

    const handleChange = (event: SelectChangeEvent) => {
        setAge(event.target.value);
    };

    const [value, setValue] = React.useState(dayjs('2022-04-17'));

    return (
        // <>
        // <h1>Package page...</h1>
        // </>
        <>
            <ThemeProvider theme={defaultTheme}>
                <Box sx={{ display: 'flex' }}>
                    <CssBaseline />
                    <Navbar />
                    <Box
                        component="main"
                        sx={{
                            backgroundColor: theme =>
                                theme.palette.mode === 'light'
                                    ? theme.palette.grey[100]
                                    : theme.palette.grey[900],
                            flexGrow: 1,
                            height: '100vh',
                            overflow: 'auto'
                        }}>
                        <Toolbar />
                        <Breadcrumbs aria-label="breadcrumb" sx={{ mt: 2, ml: 2, p: 1 }}>
                            <Link
                                underline="hover"
                                color="inherit"
                                to="/">
                                Home
                            </Link>
                            <Typography color="text.primary">Package Realization Report</Typography>
                        </Breadcrumbs>

                        <Container maxWidth='xl' sx={{ mt: 1, mb: 3 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={12} lg={12}>
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="center">
                                        <Grid item>
                                            <Button variant="outlined">
                                                <AddIcon style={{ fontSize: '1em', marginRight: '5px' }} />Add Data
                                            </Button>{' '}
                                            {/* <Button variant="outlined" style={{ marginLeft: '10px', marginRight: '15px' }}><FileDownloadIcon style={{ fontSize: '1em', marginRight: '5px' }} />Export
                                            </Button> */}



                                        </Grid>
                                        <Grid item>
                                            <FormControl sx={{ minWidth: 120, marginRight: '10px' }} size="small">
                                                <InputLabel id="demo-select-small-label">Option</InputLabel>
                                                <Select
                                                    labelId="demo-select-small-label"
                                                    id="demo-select-small"
                                                    value={age}
                                                    label="Age"
                                                    onChange={handleChange}
                                                >
                                                    <MenuItem value="">
                                                        <em>None</em>
                                                    </MenuItem>
                                                    <MenuItem value={10}>Search By Date of Package</MenuItem>
                                                    <MenuItem value={20}>Search By PRID</MenuItem>
                                                </Select>
                                            </FormControl>

                                            {/* <Paper component="form" className={classes.root} style={{ paddingLeft: '10px' }}>
                                                <InputBase
                                                    className={classes.input}
                                                    placeholder='Search package..'
                                                />
                                                <IconButton
                                                    className={classes.iconButton}
                                                    aria-label='search'>
                                                    <SearchIcon />
                                                </IconButton>
                                            </Paper> */}
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                {/* <DemoContainer components={['DatePicker', 'DatePicker']}> */}
                                                <DatePicker defaultValue={dayjs('2022-04-17')} slotProps={{ textField: { size: 'small' } }} />{' '}
                                                <DatePicker
                                                    value={value}
                                                    onChange={(newValue) => setValue(newValue)}
                                                    slotProps={{ textField: { size: 'small' } }} />
                                                {/* </DemoContainer> */}
                                            </LocalizationProvider>
                                            {/* <Box> */}
                                            <InputBase
                                                className={classes.input}
                                                placeholder='Search package..'
                                            />
                                            <IconButton
                                                className={classes.iconButton}
                                                aria-label='search'>
                                                <SearchIcon />
                                            </IconButton>
                                            {/* </Box> */}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={12} lg={12} sx={{ mt: 2 }}>
                                <Paper
                                    sx={{
                                        p: 2,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        height: 'auto'
                                    }}>
                                    <TableContainer component={Paper}>
                                        <Table aria-label="collapsible table" size="small">

                                            <TableHead>
                                                <TableRow>
                                                    <TableCell rowSpan={2} align="center" style={{ width: '3px', fontWeight: 'bold' }}></TableCell>
                                                    {/* <TableCell rowSpan={2} align="center" style={{ fontWeight: 'bold' }}>No</TableCell> */}
                                                    <TableCell rowSpan={2} align="center" style={{ fontWeight: 'bold' }}>PR ID</TableCell>
                                                    <TableCell rowSpan={2} align="center" style={{ fontWeight: 'bold' }}>Package Real Name</TableCell>
                                                    <TableCell rowSpan={2} align="center" style={{ fontWeight: 'bold' }}>Package Name</TableCell>
                                                    <TableCell rowSpan={2} align="center" style={{ fontWeight: 'bold' }}>Shift</TableCell>
                                                    <TableCell rowSpan={2} align="center" style={{ fontWeight: 'bold' }}>Start Date</TableCell>
                                                    <TableCell rowSpan={2} align="center" style={{ fontWeight: 'bold' }}>End Date</TableCell>
                                                    <TableCell rowSpan={2} align="center" style={{ fontWeight: 'bold' }}>Status</TableCell>
                                                    <TableCell colSpan={2} align="center" style={{ fontWeight: 'bold' }}>
                                                        Students
                                                    </TableCell>
                                                    <TableCell rowSpan={2} align="center" style={{ fontWeight: 'bold' }}>
                                                        Total Students
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell align="center" style={{ fontWeight: 'bold' }}>Binusian</TableCell>
                                                    <TableCell align="center" style={{ fontWeight: 'bold' }}>Non-Binusian</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {rows.map((row) => (
                                                    <Row key={row.no} row={row} />
                                                ))}

                                                {/* {rows.map((row, index) => (
                                                    <>
                                                        <TableCell>
                                                            <IconButton
                                                                aria-label="expand row"
                                                                size="small"
                                                                onClick={() => setOpen(!open)}>
                                                                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                                            </IconButton>
                                                        </TableCell>
                                                        <TableCell>{row.no}</TableCell>
                                                        <TableCell>{row.prid}</TableCell>
                                                        <TableCell>{row.package_real_name}</TableCell>
                                                        <TableCell>{row.package_name}</TableCell>
                                                        <TableCell>{row.shift}</TableCell>
                                                        <TableCell>{row.start_date}</TableCell>
                                                        <TableCell>{row.end_date}</TableCell>
                                                        <TableCell align='center'>{row.status}</TableCell>
                                                        <TableCell align='center'>{row.binusian}</TableCell>
                                                        <TableCell align='center'>{row.non_binusian}</TableCell>
                                                        <TableCell align='center'>{row.total_students}</TableCell>

                                                        <TableRow>
                                                            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                                                                <Collapse in={open} timeout="auto" unmountOnExit>
                                                                    <Box sx={{ margin: 1 }}>
                                                                        <Typography variant="h6" gutterBottom component="div" sx={{ mt: '10px', mb: '15px' }}>
                                                                            Action <br />
                                                                            <Grid sx={{ pt: 1, pb: 2 }}>
                                                                                <Button variant="outlined" style={{ marginRight: '5px' }}><DetailsIcon style={{ fontSize: '1em', marginRight: '5px' }} />View</Button>{' '}
                                                                                <Button variant="outlined" style={{ marginRight: '5px' }}><EditIcon style={{ fontSize: '1em', marginRight: '5px' }} />Edit</Button>{' '}
                                                                                <Button variant="outlined" style={{ marginRight: '5px' }}><DeleteIcon style={{ fontSize: '1em', marginRight: '5px' }} />Delete</Button>
                                                                            </Grid>
                                                                        </Typography>

                                                                    </Box>
                                                                </Collapse>
                                                            </TableCell>
                                                        </TableRow>

                                                    </>
                                                ))} */}

                                            </TableBody>

                                        </Table>
                                    </TableContainer>
                                </Paper>
                            </Grid>

                            {/* Page size and Pagination */}
                            <Grid container spacing={3} sx={{ pt: 2 }} direction="row" justifyContent="space-between" alignItems="center">
                                <Grid item>
                                    <FormControl sx={{ m: 1, minWidth: 75 }} size="small">
                                        <Select
                                            value={age}
                                            onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}>
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            <MenuItem value={10}>5</MenuItem>
                                            <MenuItem value={20}>10</MenuItem>
                                            <MenuItem value={30}>15</MenuItem>
                                            <MenuItem value={40}>20</MenuItem>
                                            <MenuItem value={50}>25</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item>
                                    <Pagination count={3} />
                                </Grid>
                            </Grid>
                        </Container>

                    </Box>
                </Box>
            </ThemeProvider>
        </>
    )
}

export default Package;