import React, { useState } from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import { Button } from '@material-ui/core';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';

import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Breadcrumbs from '@mui/material/Breadcrumbs';

import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';

import Navbar from '../components/NewNavbar';

import ProgressRegular from '../pages/contents/courseProgressRegular';
import ProgressPrakerja from '../pages/contents/courseProgressPrakerja';
import TotalRegular from '../pages/contents/courseTotalRegular';
import TotalPrakerja from '../pages/contents/courseTotalPrakerja';
import CategoryChart from '../pages/contents/categoryChart';
import PrakerjaChart from '../pages/contents/prakerjaChart';

import { Typography } from '@mui/material';

// import { red } from '@mui/material/colors';
import { yellow } from '@mui/material/colors';
import { green } from '@mui/material/colors';

/*** Import icons */
import FolderIcon from '@mui/icons-material/Folder';
import DeleteIcon from '@mui/icons-material/Delete';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import TimelineIcon from '@mui/icons-material/Timeline';
import TaskAltIcon from '@mui/icons-material/TaskAlt';


import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { pink } from '@mui/material/colors';
import { red } from '@mui/material/colors'

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
    },
}));

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary
}))

const useStyles = makeStyles(theme => ({
    table: {
        minWidth: 650
    },
    root: {
        // padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 400
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1
    },
    iconButton: {
        padding: 10
    },
    divider: {
        height: 28,
        margin: 4
    },
    titleItemRight: {
        color: "white",
        backgroundColor: "blue",
        top: "50%",
        height: 30,
        float: "right",
        position: "relative",
        transform: "translateY(-50%)"
    }
}))

const defaultTheme = createTheme()

const Dashboard2 = () => {

    const classes = useStyles()
    const [secondary, setSecondary] = useState(false);

    return (
        // <>
        // <h1>This is Dashboard for Curriculum only</h1>
        // <h2>This is still on developing phase</h2>
        // </>

        <ThemeProvider theme={defaultTheme}>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <Navbar />
                <Box
                    component='main'
                    sx={{
                        backgroundColor: theme =>
                            theme.palette.mode === 'light'
                                ? theme.palette.grey[100]
                                : theme.palette.grey[900],
                        flexGrow: 1,
                        height: '100vh',
                        overflow: 'auto'
                    }}
                >
                    <Toolbar />
                    <Breadcrumbs aria-label='breadcrumb' sx={{ mt: 2, ml: 2, p: 1 }}>
                        <Link underline="hover" color="inherit" to="/">Home</Link>
                        <Typography color="text.primary">Curriculum Dashboard</Typography>
                    </Breadcrumbs>
                    <Container maxWidth='xl' sx={{ mt: 1, mb: 3 }}>
                        <Grid container spacing={3}>
                            {/* SECTION #1 */}
                            <Grid item xs={12} sx={{ mb: -2.25 }}>
                                <Typography variant="h6">Regular Course</Typography>
                            </Grid>
                            <Grid item xs={12} md={3} lg={3}>
                                <Paper
                                    sx={{
                                        p: 2,
                                        display: 'flex',
                                        flexDirection: 'column'
                                    }}
                                >
                                    <ProgressRegular />
                                </Paper>
                            </Grid>
                            <Grid item xs={12} md={3} lg={3}>
                                <Paper
                                    sx={{
                                        p: 2,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        // height: 240
                                    }}
                                >
                                    <TotalRegular />
                                </Paper>
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <Paper
                                    sx={{
                                        p: 2,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        // height: 240
                                    }}
                                >
                                    <Grid item xs={12} md={12}>
                                        {/* <h6 style={{ textAlign: 'center', marginTop: '5px', fontWeight: 'bold' }}>Progress Regular</h6> */}
                                        <List disablePadding>
                                            <ListItem
                                                secondaryAction={
                                                    <ListItemText style={{ textAlign: 'center' }}
                                                        primary="93.91%"
                                                        secondary={<small>7 Sept 23</small>}
                                                    />
                                                }>
                                                <ListItemAvatar>
                                                    {/* <Avatar style={{backgroundColor:'lightgray'}}> */}
                                                    <StopCircleIcon sx={{ color: red[500] }} />
                                                    {/* </Avatar> */}
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary="Not Started"
                                                    secondary={<BorderLinearProgress variant="determinate" value={93.91} style={{ width: '80%' }} color="info" />}
                                                />
                                            </ListItem>
                                            <ListItem
                                                secondaryAction={
                                                    <ListItemText style={{ textAlign: 'center' }}
                                                        primary="6.09%"
                                                        secondary={<small>7 Sept 23</small>}
                                                    />
                                                }>
                                                <ListItemAvatar>
                                                    {/* <Avatar style={{backgroundColor:'lightgray'}}> */}
                                                    <TimelineIcon sx={{ color: green[500] }} />
                                                    {/* </Avatar> */}
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary="Development"
                                                    secondary={<BorderLinearProgress variant="determinate" value={6.09} style={{ width: '80%' }} />}
                                                />
                                            </ListItem>
                                            <ListItem
                                                secondaryAction={
                                                    <ListItemText style={{ textAlign: 'center' }}
                                                        primary="0%"
                                                        secondary={<small>7 Sept 23</small>}
                                                    />
                                                }>
                                                <ListItemAvatar>
                                                    {/* <Avatar style={{backgroundColor:'lightgray'}}> */}
                                                    <TaskAltIcon sx={{ color: green[500] }} />
                                                    {/* </Avatar> */}
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary="Done"
                                                    secondary={<BorderLinearProgress variant="determinate" value={0} style={{ width: '80%' }} />}
                                                />
                                            </ListItem>
                                        </List>
                                    </Grid>

                                </Paper>
                            </Grid>

                            {/* SECTION #2 */}
                            <Grid item xs={12} sx={{ mb: -2.25 }}>
                                <Typography variant="h6">Prakerja Course</Typography>
                            </Grid>
                            <Grid item xs={12} md={3} lg={3}>
                                <Paper
                                    sx={{
                                        p: 2,
                                        display: 'flex',
                                        flexDirection: 'column'
                                    }}
                                >
                                    <ProgressPrakerja />
                                </Paper>
                            </Grid>
                            <Grid item xs={12} md={3} lg={3}>
                                <Paper
                                    sx={{
                                        p: 2,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        // height: 240
                                    }}
                                >
                                    <TotalPrakerja />
                                </Paper>
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <Paper
                                    sx={{
                                        p: 2,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        // height: 240
                                    }}
                                >
                                    <Grid item xs={12} md={12}>
                                        {/* <h6 style={{ textAlign: 'center', marginTop: '5px', fontWeight: 'bold' }}>Progress Prakerja</h6> */}
                                        <List disablePadding>
                                            <ListItem
                                                secondaryAction={
                                                    <ListItemText style={{ textAlign: 'center' }}
                                                        primary="66.67%"
                                                        secondary={<small>7 Sept 23</small>}
                                                    />
                                                }>
                                                <ListItemAvatar>
                                                    {/* <Avatar style={{backgroundColor:'lightgray'}}> */}
                                                    <StopCircleIcon sx={{ color: red[500] }} />
                                                    {/* </Avatar> */}
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary="Not Started"
                                                    secondary={<BorderLinearProgress variant="determinate" value={66.67} style={{ width: '80%' }} color="info" />}
                                                />
                                            </ListItem>
                                            <ListItem
                                                secondaryAction={
                                                    <ListItemText style={{ textAlign: 'center' }}
                                                        primary="33.33%"
                                                        secondary={<small>7 Sept 23</small>}
                                                    />
                                                }>
                                                <ListItemAvatar>
                                                    {/* <Avatar style={{backgroundColor:'lightgray'}}> */}
                                                    <TimelineIcon sx={{ color: green[500] }} />
                                                    {/* </Avatar> */}
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary="Development"
                                                    secondary={<BorderLinearProgress variant="determinate" value={33.33} style={{ width: '80%' }} />}
                                                />
                                            </ListItem>
                                            <ListItem
                                                secondaryAction={
                                                    <ListItemText style={{ textAlign: 'center' }}
                                                        primary="0%"
                                                        secondary={<small>7 Sept 23</small>}
                                                    />
                                                }>
                                                <ListItemAvatar>
                                                    {/* <Avatar style={{backgroundColor:'lightgray'}}> */}
                                                    <TaskAltIcon sx={{ color: green[500] }} />
                                                    {/* </Avatar> */}
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary="Done"
                                                    secondary={<BorderLinearProgress variant="determinate" value={0} style={{ width: '80%' }} />}
                                                />
                                            </ListItem>
                                        </List>
                                    </Grid>

                                </Paper>
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <Paper
                                    sx={{
                                        p: 2,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        height: 'auto'
                                    }}
                                >
                                    {/* Content #4 */}
                                    <CategoryChart />
                                </Paper>
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <Paper
                                    sx={{
                                        p: 2,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        height: 'auto'
                                    }}
                                >
                                    {/* Content #4 */}
                                    <PrakerjaChart />
                                </Paper>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
            </Box>
        </ThemeProvider>
    )
}

export default Dashboard2