import React from 'react';
import Typography from '@mui/material/Typography';
import { Link } from "react-router-dom";
import FeedIcon from '@mui/icons-material/Feed';
import PsychologyIcon from '@mui/icons-material/Psychology';
import Tooltip from '@mui/material/Tooltip';

const totalPrakerja = () => {
    return (
        <>
            {/* <h1>Total Prakerja</h1> */}
            <h6 style={{ textAlign: 'center', marginTop: '27px' }}>Total Course Regular</h6>
            <Typography component="p" variant="h2" style={{ paddingTop: '10px', textAlign: 'center' }}>
                <Tooltip arrow title="Check All Prakerja Courses">
                    <Link underline="hover" to="/prakerja" style={{ textDecoration: 'none', cursor: 'pointer', color: 'black' }}>
                        3
                    </Link>
                </Tooltip>
            </Typography>
            <div style={{ textAlign: 'center', paddingBottom: '25px' }}>
                <small><PsychologyIcon style={{ fontSize: '17px' }} /> Teknik Membuat Website Statis..</small>
            </div>
        </>
    )
}

export default totalPrakerja;