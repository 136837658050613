import React, { useState, useEffect } from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles'
import { Button } from '@material-ui/core'
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Breadcrumbs from '@mui/material/Breadcrumbs';
// import Link from '@mui/material/Link';

import { Link } from "react-router-dom";

import LogoutIcon from '@mui/icons-material/Logout'
import MenuIcon from '@mui/icons-material/Menu'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Typography from '@mui/material/Typography';
// import FolderIcon from '@mui/icons-material/Folder';
// import DeleteIcon from '@mui/icons-material/Delete';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import { BookTwoTone, ApiTwoTone, CheckCircleTwoTone, HeartTwoTone, SmileTwoTone } from '@ant-design/icons';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
// import Paper from '@mui/material/Paper';

import { makeStyles } from '@material-ui/core/styles'

// import Navbar from '../components/Navbar';
import Navbar from '../components/NewNavbar';
// import Deposits from '../pages/contents/Deposits';
import Course from '../pages/contents/courseTotal';
import Category from '../pages/contents/categoryTotal';
import Instructor from '../pages/contents/instructorTotal';
import Location from '../pages/contents/locationTotal';
import User from '../pages/contents/userTotal';

const Demo = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
}));

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary
}))

const useStyles = makeStyles(theme => ({
    table: {
        minWidth: 650
    },
    root: {
        // padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 400
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1
    },
    iconButton: {
        padding: 10
    },
    divider: {
        height: 28,
        margin: 4
    }
}))

const defaultTheme = createTheme()

// function generate(element) {
//     return [0, 1, 2, 3, 4].map((value) =>
//         React.cloneElement(element, {
//             key: value,
//         }),
//     );
// }

function createData(no, course, category, release, totalUser, schedule, mode) {
    return { no, course, category, release, totalUser, schedule, mode }
}

const rows = [
    createData(1, 'Python for Beginner', 'programming', 'v.1', 200, '2 Juni 2023', 'hybrid'),
    createData(2, 'Python for Data Science', 'programming', 'v.1', 131, '3 Juni 2023', 'daring'),
    createData(3, 'AWS Certification', 'certificate', 'v.1', 120, '2 Juni 2023', 'hybrid'),
    createData(4, 'Algoritma for Data Science', 'programming', 'v.1', 100, '5 Juni 2023', 'luring'),
    createData(5, 'Java for Beginner', 'programming', 'v.3', 99, '10 Juni 2023', 'hybrid')
]


const Dashboard = () => {

    const classes = useStyles()
    // const [dense, setDense] = React.useState(false);
    // const [secondary, setSecondary] = React.useState(false);

    var [date, setDate] = useState(new Date());


    useEffect(() => {
        var timer = setInterval(() => setDate(new Date()), 1000)

        return function cleanup() {
            clearInterval(timer);
        }
    })


    return (
        <ThemeProvider theme={defaultTheme}>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />

                <Navbar />
                <Box
                    component='main'
                    sx={{
                        backgroundColor: theme =>
                            theme.palette.mode === 'light'
                                ? theme.palette.grey[100]
                                : theme.palette.grey[900],
                        flexGrow: 1,
                        height: '100vh',
                        overflow: 'auto'
                    }}
                >
                    <Toolbar />
                    {/* <Container maxWidth='lg' sx={{ mt: 4, mb: 4 }}>
                        <h1>Dashboard page</h1>
                    </Container> */}
                    <Grid container spacing={24} justifyContent="space-between">
                        <Grid item>
                            <Breadcrumbs aria-label='breadcrumb' sx={{ mt: 2, ml: 2, p: 1 }}>
                                {/* <Link underline="hover" color="inherit" to="/">Home</Link> */}
                                <Typography color="text.primary">Home </Typography>
                            </Breadcrumbs>
                        </Grid>
                        <Grid item>
                            {/* <div>
                                <p> Time : {date.toLocaleTimeString()}</p>
                                <p>Date : {date.toLocaleDateString()}</p>
                            </div> */}
                            <div style={{paddingTop: '20px', paddingRight:'25px'}}>
                                <CalendarTodayIcon />
                                {/* <p>time</p> */}

                               <span style={{marginTop:'10px'}}>{" "}{date.toLocaleDateString()}</span>
                                <AccessTimeIcon style={{marginLeft:'15px'}}/>
                                {/* <p>date</p> */}
                                {" "}{date.toLocaleTimeString()}
                            </div>
                        </Grid>
                    </Grid>

                    <Container maxWidth='xl' sx={{ mt: 4, mb: 4 }}>
                        <Grid container spacing={3}>
                            {/* SECTION #1 */}
                            <Grid item xs={12} md={3} lg={3}>
                                <Paper
                                    sx={{
                                        p: 2,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        height: 240
                                    }}
                                >
                                    {/* Content #1 */}
                                    {/* <Deposits /> */}
                                    <Course />
                                </Paper>
                            </Grid>
                            <Grid item xs={12} md={3} lg={3}>
                                <Paper
                                    sx={{
                                        p: 2,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        height: 240
                                    }}
                                >
                                    {/* Content #2 */}
                                    <Category />
                                </Paper>
                            </Grid>
                            <Grid item xs={12} md={3} lg={3}>
                                <Paper
                                    sx={{
                                        p: 2,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        height: 240
                                    }}
                                >
                                    {/* Content #3 */}
                                    <Instructor />
                                </Paper>
                            </Grid>
                            <Grid item xs={12} md={3} lg={3}>
                                <Paper
                                    sx={{
                                        p: 2,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        height: 240
                                    }}
                                >
                                    {/* Content #4 */}
                                    {/* <User /> */}
                                    <Location />
                                </Paper>
                            </Grid>
                            {/* SECTION #2 */}
                            <Grid item xs={12} md={6} lg={6}>
                                <Paper
                                    sx={{
                                        p: 2,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        height: 'auto'
                                    }}
                                >
                                    {/* Content #4 */}
                                    <Typography sx={{ mb: 1 }} variant="h6" component="div">
                                        Users Proportion <Link to="/users" size="small" style={{ marginLeft: '10px', fontSize: '14px' }}>All Users</Link>
                                    </Typography>
                                    <User />
                                </Paper>
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <Paper
                                    sx={{
                                        p: 2,
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}
                                >
                                    {/* Content #5 */}

                                    <Typography sx={{ mb: 1 }} variant="h6" component="div">
                                        Top 5 Category Rank
                                    </Typography>
                                    <Demo>
                                        <List>
                                            <ListItem
                                                secondaryAction={

                                                    <IconButton edge="end" aria-label="delete">
                                                        45
                                                        <AutoStoriesIcon style={{ marginLeft: '5px' }} />
                                                    </IconButton>

                                                }
                                            >
                                                <ListItemAvatar>
                                                    <SmileTwoTone style={{ fontSize: '1.5rem' }} />
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary="Pra Kerja"
                                                    secondary="+2 modules Jan, 2023"
                                                />
                                            </ListItem>

                                        </List>
                                        <List>
                                            <ListItem
                                                secondaryAction={

                                                    <IconButton edge="end" aria-label="delete">
                                                        80
                                                        <AutoStoriesIcon style={{ marginLeft: '5px' }} />
                                                    </IconButton>

                                                }
                                            >
                                                <ListItemAvatar>
                                                    <CheckCircleTwoTone style={{ fontSize: '1.5rem' }} />
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary="In house"
                                                    secondary="+10 modules Jan, 2023"
                                                />
                                            </ListItem>

                                        </List>
                                        <List>
                                            <ListItem
                                                secondaryAction={

                                                    <IconButton edge="end" aria-label="delete">
                                                        15
                                                        <AutoStoriesIcon style={{ marginLeft: '5px' }} />
                                                    </IconButton>

                                                }
                                            >
                                                <ListItemAvatar>
                                                    <HeartTwoTone style={{ fontSize: '1.5rem' }} />
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary="Special Class"
                                                    secondary="+2 modules Jan, 2023"
                                                />
                                            </ListItem>

                                        </List>
                                        <List>
                                            <ListItem
                                                secondaryAction={

                                                    <IconButton edge="end" aria-label="delete">
                                                        11
                                                        <AutoStoriesIcon style={{ marginLeft: '5px' }} />
                                                    </IconButton>

                                                }
                                            >
                                                <ListItemAvatar>
                                                    <ApiTwoTone style={{ fontSize: '1.5rem' }} />
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary="Programming"
                                                    secondary="+2 modules Jan, 2023"
                                                />
                                            </ListItem>

                                        </List>
                                        <List>
                                            <ListItem
                                                secondaryAction={

                                                    <IconButton edge="end" aria-label="delete">
                                                        12
                                                        <AutoStoriesIcon style={{ marginLeft: '5px' }} />
                                                    </IconButton>

                                                }
                                            >
                                                <ListItemAvatar>
                                                    <BookTwoTone style={{ fontSize: '1.5rem' }} />
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary="Sertifikasi"
                                                    secondary="+3 modules Jan, 2023"
                                                />
                                            </ListItem>

                                        </List>
                                    </Demo>
                                </Paper>
                            </Grid>
                            {/* SECTION #3 */}
                            <Grid item xs={12}>
                                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                                    {/* Content #6 */}
                                    <Typography sx={{ mb: 1 }} variant="h6" component="div">
                                        Top 5 Course Rank
                                    </Typography>
                                    <TableContainer component={Paper}>
                                        <Table sx={{ minWidth: 650 }} aria-label="basic table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell style={{ fontWeight: 'bold' }} align='center'>No</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold' }} align='center'>Name</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold' }} align='center'>Category</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold' }} align='center'>Release</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold' }} align='center'>Total User</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold' }}>Schedule</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold' }} align='center'>Mode</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {rows.map((row) => (
                                                    <TableRow
                                                        key={row.no}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                        <TableCell component="th" scope="row" align='center'>
                                                            {row.no}
                                                        </TableCell>
                                                        <TableCell>
                                                            {row.course}
                                                        </TableCell>
                                                        <TableCell align='center'>
                                                            {row.category}
                                                        </TableCell>
                                                        <TableCell style={{ textAlign: 'center' }}>
                                                            {row.release}
                                                        </TableCell>
                                                        <TableCell align='center'>
                                                            {row.totalUser}
                                                        </TableCell>
                                                        <TableCell>
                                                            {row.schedule}
                                                        </TableCell>
                                                        <TableCell align='center'>
                                                            {row.mode}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
            </Box>
        </ThemeProvider>
    )
}

export default Dashboard;