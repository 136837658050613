import React from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles'
import { Button } from '@material-ui/core'
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';

/**************************************
 * For pdf viewer
 **************************************/
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { toolbarPlugin, ToolbarSlot } from '@react-pdf-viewer/toolbar';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

// import demoFile from '../document.pdf';
import demoFile from '../Python_Programming.pdf';

import { makeStyles } from '@material-ui/core/styles';

// import Navbar from '../components/Navbar';
import Navbar from '../components/NewNavbar';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary
}))

const useStyles = makeStyles(theme => ({
    table: {
        minWidth: 650
    },
    root: {
        // padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 400
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1
    },
    iconButton: {
        padding: 10
    },
    divider: {
        height: 28,
        margin: 4
    }
}))

const defaultTheme = createTheme()

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: `-${drawerWidth}px`,
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        }),
    }),
);

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

const DetailCourse = () => {

    const classes = useStyles()

    const [open, setOpen] = React.useState(false);


    const renderToolbar = (Toolbar: (props: ToolbarProps) => ReactElement) => (
        <Toolbar>
            {(slots: ToolbarSlot) => {
                const { ZoomOut } = slots;
                return (
                    <div
                        style={{
                            alignItems: "center",
                            display: "flex"
                        }}
                    >
                        <div style={{ padding: "0px 2px" }}>
                            <ZoomOut>
                                {(props) => (
                                    <button
                                        style={{
                                            backgroundColor: "#357edd",
                                            border: "none",
                                            borderRadius: "4px",
                                            color: "#ffffff",
                                            cursor: "pointer",
                                            padding: "8px"
                                        }}
                                        onClick={props.onClick}
                                    >
                                        Zoom out
                                    </button>
                                )}
                            </ZoomOut>
                        </div>

                    </div>
                );
            }}
        </Toolbar>
    );

    const defaultLayoutPluginInstance = defaultLayoutPlugin();


    return (
        // <>
        // <h1>Detail Course page...</h1>
        // </>
        <ThemeProvider theme={defaultTheme}>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />

                <Navbar />
                <Main open={open} style={{marginLeft:'10px', width: '640px'}}>
                    <DrawerHeader />
                    <Typography paragraph>
                        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
                            <div style={{ height: "720px"}}>
                                <Viewer fileUrl={demoFile} plugins={[defaultLayoutPluginInstance]} />
                            </div>
                        </Worker>
                    </Typography>
                </Main>



            </Box>

        </ThemeProvider>
    )
}

export default DetailCourse;