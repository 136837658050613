import React from 'react';
import PropTypes from 'prop-types';
import Pagination from '@mui/material/Pagination';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles'
import { Button, InputBase } from '@material-ui/core'
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Breadcrumbs from '@mui/material/Breadcrumbs';

import { Link } from "react-router-dom";

import { makeStyles } from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

/****** Import all icons ******/
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import IconButton from '@mui/material/IconButton'
import AddIcon from '@mui/icons-material/Add'
import SearchIcon from '@material-ui/icons/Search'
import SendIcon from '@mui/icons-material/Send';
import DetailsIcon from '@mui/icons-material/Details';
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import FileDownloadIcon from '@mui/icons-material/FileDownload';

import Collapse from '@mui/material/Collapse';

import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

// import Navbar from '../components/Navbar';
import Navbar from '../components/NewNavbar';
import { Typography } from '@mui/material';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary
}))

const useStyles = makeStyles(theme => ({
    table: {
        minWidth: 'auto'
    },
    root: {
        // padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 400
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1
    },
    iconButton: {
        padding: 10
    },
    divider: {
        height: 28,
        margin: 4
    }
}))

const defaultTheme = createTheme()

function createData(fullname, email, telephone, courseID, prakerjaID, user_types, detail) {
    return {
        fullname,
        email,
        telephone,
        courseID,
        prakerjaID,
        user_types,
        history: [
            {
                start_date: '2020-01-05',
                end_date: '2020-01-05',
                course_name: 'Belajar Python untuk Pemula',
                certificate: '222345'
            },
            {
                start_date: '2021-01-05',
                end_date: '2020-01-05',
                course_name: 'Belajar Pemrograman Website dengan menggunakan PHP dan MySQL',
                certificate: '223122'
            },
        ],
        detail
    }
}

function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);

    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    {row.fullname}
                </TableCell>
                <TableCell align="left">{row.email}</TableCell>
                <TableCell align="right">{row.telephone}</TableCell>
                <TableCell align="center">{row.courseID}</TableCell>
                <TableCell align="center">{row.prakerjaID}</TableCell>
                <TableCell align="center">{row.user_types}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Typography variant="h6" gutterBottom component="div">
                                Course History
                            </Typography>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell><b>Start Date</b></TableCell>
                                        <TableCell><b>End Date</b></TableCell>
                                        <TableCell align="center"><b>Course Name</b></TableCell>
                                        {/* <TableCell align="right">Total Price ($)</TableCell> */}
                                        <TableCell align="center"><b>No. Certificate</b></TableCell>
                                        {/* <TableCell align="center">Action</TableCell> */}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {row.history.map((historyRow) => (
                                        <TableRow key={historyRow.course_name}>
                                            <TableCell component="th" scope="row">
                                                {historyRow.start_date}
                                            </TableCell>
                                            <TableCell>
                                                {historyRow.end_date}
                                            </TableCell>
                                            <TableCell align="left">
                                                {historyRow.course_name}
                                            </TableCell>
                                            {/* <TableCell align="right">{Math.round(historyRow.amount * row.price * 100) / 100}</TableCell> */}
                                            <TableCell align="center">{historyRow.certificate}</TableCell>
                                            {/* <TableCell align="center">
                                                <Button variant="outlined">Edit</Button>{' '}
                                                <Button variant="outlined">Delete</Button>
                                            </TableCell> */}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            <Typography variant="h6" gutterBottom component="div" sx={{ mt: '10px', mb: '15px' }}>
                                Action <br />
                                {/* <p>{row.detail}</p> */}
                                <Grid sx={{ pt: 1, pb: 2 }}>
                                    <Button variant="outlined" style={{ marginRight: '5px' }}><SendIcon style={{fontSize: '1em', marginRight:'5px'}} />Send Email</Button>{' '}
                                    <Button variant="outlined" style={{ marginRight: '5px' }}><DetailsIcon style={{fontSize: '1em', marginRight:'5px'}} />View</Button>{' '}
                                    <Button variant="outlined" style={{ marginRight: '5px' }}><EditIcon style={{ fontSize: '1em', marginRight: '5px' }} />Edit</Button>{' '}
                                    <Button variant="outlined" style={{ marginRight: '5px' }}><DeleteIcon style={{ fontSize: '1em', marginRight: '5px' }} />Delete</Button>
                                </Grid>
                            </Typography>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    )
}

Row.propTypes = {
    row: PropTypes.shape({
        fullname: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
        telephone: PropTypes.number.isRequired,
        history: PropTypes.arrayOf(
            PropTypes.shape({
                start_date: PropTypes.string.isRequired,
                end_date: PropTypes.string.isRequired,
                course_name: PropTypes.string.isRequired,
                certificate: PropTypes.string.isRequired,
            }),
        ).isRequired,
        courseID: PropTypes.number.isRequired,
        prakerjaID: PropTypes.number.isRequired,
        user_types: PropTypes.string.isRequired,
    }).isRequired,
};

const rows = [
    createData('Adhitya Dwijayantho', 'adhitya.dwijayantho@binus.edu', '087761665767', '22301', '', 'binusian', 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'),
    createData('Tony Stark', 'tony.stark@starkindustries.com', '99182736', '2235', '', 'non-binusian', 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'),
    createData('Bruce Banner', 'bruce.banner@avengers.com', '8485966', '', '33123', 'non-binusian', 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'),
    createData('Steve Rogers', 'roger.steve@avengers.com', '8756606', '33545', '', 'non-binusian', 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'),
    createData('Peter Parker', 'peter.parker@binus.edu', '087761665677', '22313', '', 'binusian', 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'),
]


const Members = (props) => {
    const { row } = props;
    const [open, setOpen] = React.useState(false);

    const classes = useStyles()

    const [age, setAge] = React.useState('');

    const handleChange = (event: SelectChangeEvent) => {
        setAge(event.target.value);
    };

    // return (
    //     <>
    //     <h1>Users page</h1>
    //     </>
    // )
    return (
        <ThemeProvider theme={defaultTheme}>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />

                <Navbar />
                <Box
                    component='main'
                    sx={{
                        backgroundColor: theme =>
                            theme.palette.mode === 'light'
                                ? theme.palette.grey[100]
                                : theme.palette.grey[900],
                        flexGrow: 1,
                        height: '100vh',
                        overflow: 'auto'
                    }}
                >
                    <Toolbar />
                    <Breadcrumbs aria-label="breadcrumb" sx={{ mt: 2, ml: 2, p: 1 }}>
                        {/* <Link underline="hover" color="inherit" href="/">
                                MUI
                            </Link> */}
                        <Link
                            underline="hover"
                            color="inherit"
                            to="/"
                        >
                            Home
                        </Link>
                        <Typography color="text.primary">All Member</Typography>
                    </Breadcrumbs>
                    <Container maxWidth='xl' sx={{ mt: 1, mb: 3 }}>
                        {/* <Typography><h6><ChevronRightIcon />{' '}Courses</h6></Typography> */}
                        <Grid container spacing={3}>
                            {/* SECTION #1 */}
                            <Grid item xs={12} md={12} lg={12}>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center">
                                    <Grid item>
                                        <Button variant="outlined">
                                            <AddIcon style={{ fontSize: '1em', marginRight: '5px' }} />Add Data
                                        </Button>{' '}
                                        <Button variant="outlined" style={{ marginLeft: '10px' }}><FileDownloadIcon style={{ fontSize: '1em', marginRight: '5px' }} />Export
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Paper component="form" className={classes.root} style={{ paddingLeft: '10px' }}>
                                            <InputBase
                                                className={classes.input}
                                                placeholder='Search users...'
                                            />
                                            {/* <IconButton
                                                className={classes.iconButton}
                                                aria-label='search' /> */}
                                            <IconButton
                                                className={classes.iconButton}
                                                aria-label='search'>
                                                <SearchIcon />
                                            </IconButton>
                                        </Paper>
                                    </Grid>
                                </Grid>
                            </Grid>


                            <Grid item xs={12} md={12} lg={12}>
                                <Paper
                                    sx={{
                                        p: 2,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        height: 'auto'
                                    }}
                                >
                                    {/* Content #3 Here!!! */}

                                    <TableContainer component={Paper}>
                                        <Table aria-label="collapsible table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell />
                                                    <TableCell><b>Fullname</b></TableCell>
                                                    <TableCell align="center"><b>Email</b></TableCell>
                                                    <TableCell align="center"><b>Telephone</b></TableCell>
                                                    <TableCell align="center"><b>Course ID</b></TableCell>
                                                    <TableCell align="center"><b>Prakerja ID</b></TableCell>
                                                    <TableCell align="center"><b>User Type</b></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {rows.map((row) => (
                                                    <Row key={row.name} row={row} />
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Paper>
                            </Grid>

                        </Grid>

                        <Grid container spacing={3} sx={{ pt: 2 }} direction="row" justifyContent="space-between" alignItems="center">
                            <Grid item>
                                <FormControl sx={{ m: 1, minWidth: 75 }} size="small">
                                    <Select
                                        value={age}
                                        onChange={handleChange}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}>
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        <MenuItem value={10}>5</MenuItem>
                                        <MenuItem value={20}>10</MenuItem>
                                        <MenuItem value={30}>15</MenuItem>
                                        <MenuItem value={40}>20</MenuItem>
                                        <MenuItem value={50}>25</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item>
                                <Pagination count={3} />
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
            </Box>
        </ThemeProvider>
    )
}

export default Members;   