// import React from 'react';
// import { BarChart } from '@mui/x-charts/BarChart';
// import { PieChart } from '@mui/x-charts/PieChart';

import React, { useRef, useLayoutEffect } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

// am4core.useTheme(am4themes_animated);

// const chartSetting = {
//     xAxis: [
//         {
//             label: 'Total Course List',
//         },
//     ],
//     width: 500,
//     height: 400,
// };

// const dataset = [
//     {
//         london: 59,
//         paris: 57,
//         newYork: 86,
//         seoul: 21,
//         total: 1,
//         category: 'Web Programming',
//     },
//     {
//         london: 50,
//         paris: 52,
//         newYork: 78,
//         seoul: 28,
//         total: 1,
//         category: 'Business',
//     },
//     {
//         london: 47,
//         paris: 53,
//         newYork: 106,
//         seoul: 41,
//         total: 1,
//         category: 'Excel',
//     },
// ];

// const valueFormatter = (value) => `${value}`;


const prakerjaChart = () => {

    const chart = useRef(null);

    useLayoutEffect(() => {

        am4core.addLicense("ch-custom-attribution");


        /*****************************
         *  Pie Chart 
         *****************************/

        var chart = am4core.create("chartdiv", am4charts.PieChart);

        // Add data
        chart.data = [{
            "users": "Web Programming",
            "totals": 1,
            "color": am4core.color("#ED1C24")
        }, {
            "users": "Business",
            "totals": 1,
            "color": am4core.color("#235789")
        }, {
            "users": "Excel",
            "totals": 1,
            "color": am4core.color("#008000")
        }];


        // chart.innerRadius = am4core.percent(100)
        chart.radius = am4core.percent(48)
        // chart.paddingTop = 0;
        // chart.paddingRight = 0;
        // chart.paddingBottom = 0;
        // chart.paddingLeft = 0;

        // Add and configure Series
        var pieSeries = chart.series.push(new am4charts.PieSeries());
        pieSeries.dataFields.value = "totals";
        pieSeries.dataFields.category = "users";
        pieSeries.slices.template.propertyFields.fill = "color";

        // pieSeries.ticks.template.disabled = true;
        // pieSeries.alignLabels = true;
        pieSeries.labels.template.text = "{value.percent.formatNumber('#.0')}%";
        pieSeries.labels.template.fill = am4core.color("black");

        chart.legend = new am4charts.Legend();

        /************** end pie chart ***************/

    }, []);


    return (
        <>
            <h6 style={{ marginBottom: '-50px' }}>Prakerja Course Category</h6>
            {/* <BarChart
                dataset={dataset}
                yAxis={[{ scaleType: 'band', dataKey: 'category' }]}
                series={[{ dataKey: 'total', label: 'category', valueFormatter }]}
                layout="horizontal"
                {...chartSetting}
                margin={{left:128}}
            /> */}
            {/* <PieChart
                series={[
                    {
                        data: [
                            { id: 0, value: 10, label: 'Web Programming' },
                            { id: 1, value: 15, label: 'Business' },
                            { id: 2, value: 20, label: 'Excel' },
                        ],
                    },
                ]}
                width={480}
                height={400}
                margin={{top:40, left: 60, right:200}}
    
            /> */}
            <div id="chartdiv" style={{ width: "100%", height: "410px" }}></div>
        </>
    )
}

export default prakerjaChart