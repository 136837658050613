import React, { useState } from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { Button } from '@material-ui/core'
import CssBaseline from '@mui/material/CssBaseline'
import MuiDrawer from '@mui/material/Drawer'
import Box from '@mui/material/Box'
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { makeStyles } from '@material-ui/core/styles';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';

/**** Import icons ****/
import InboxIcon from '@material-ui/icons/MoveToInbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import SendIcon from '@material-ui/icons/Send';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import StarBorder from '@material-ui/icons/StarBorder';
import DashboardIcon from '@mui/icons-material/Dashboard';
import GolfCourseIcon from '@mui/icons-material/GolfCourse';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import GroupsIcon from '@mui/icons-material/Groups';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import RoomIcon from '@mui/icons-material/Room';
import BroadcastOnHomeIcon from '@mui/icons-material/BroadcastOnHome';
import SettingsIcon from '@mui/icons-material/Settings';
import InventoryIcon from '@mui/icons-material/Inventory';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MailIcon from '@mui/icons-material/Mail';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MoreIcon from '@mui/icons-material/MoreVert';
import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import GridViewIcon from '@mui/icons-material/GridView';
import TuneIcon from '@mui/icons-material/Tune';
import PeopleIcon from '@mui/icons-material/People';

import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import AuthService from '../services/authService';
import { Routes, Route, Link } from 'react-router-dom';


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 240,
        backgroundColor: theme.palette.background.paper
    },
    nested: {
        paddingLeft: theme.spacing(4)
    },
    nestedSecondLevel: {
        paddingLeft: theme.spacing(8)
    }
}));

function Copyright(props) {
    return (
        <Typography
            variant='body2'
            color='text.secondary'
            align='center'
            {...props}
        >
            {'Copyright © '}
            <Link color='inherit' href='https://mui.com/'>
                Adhitya Dwijayantho
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    )
}

const drawerWidth = 240

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: prop => prop !== 'open'
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easingg: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    })
}))

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: prop => prop !== 'open'
})(({ theme, open }) => ({
    '& .MuiDrawer-paper': {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        }),
        boxSizing: 'border-box',
        ...(!open && {
            overflowX: 'hidden',
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen
            }),
            width: theme.spacing(7),
            [theme.breakpoints.up('sm')]: {
                width: theme.spacing(9)
            }
        })
    }
}))

const defaultTheme = createTheme()

export default function Navbar() {

    const [anchorEl, setAnchorEl] = useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);

    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const classes = useStyles();
    const [openMenuDashboard, setOpenMenuDashboard] = useState(true);
    const [openMenuSetting, setOpenMenuSetting] = useState(true);
    const [openMenuCourse, setOpenMenuCourse] = useState(true);
    const [openMenu, setOpenMenu] = useState(true);
    const [openSecondLevel, setOpenSecondLevel] = useState(false);

    const navigate = useNavigate()
    const [open, setOpen] = useState(false)
    const toggleDrawer = () => {
        setOpen(!open)
    }

    const logOut = () => {
        AuthService.logout()
        navigate('/login')
    }

    const linkTo = (url) => {
        // alert(url)
        navigate(url)
    }

    const openDashboard = () => {
        setOpenMenuDashboard(!openMenuDashboard)
    }

    const openSetting = () => {
        setOpenMenuSetting(!openMenuSetting);
    }

    const openCourse = () => {
        setOpenMenuCourse(!openMenuCourse)
    }

    const handleClick = () => {
        setOpenMenu(!openMenu);
    }
    const handleClickSecondLevel = () => {
        setOpenSecondLevel(!openSecondLevel);
    }


    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const menuId = 'primary-search-account-menu';
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={menuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
            <MenuItem onClick={handleMenuClose}>My account</MenuItem>
            <MenuItem onClick={handleMenuClose}>Log Out</MenuItem>
        </Menu>
    );

    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            <MenuItem>
                <IconButton size="large" aria-label="show 4 new mails" color="inherit">
                    <Badge badgeContent={4} color="error">
                        <MailIcon />
                    </Badge>
                </IconButton>
                <p>Messages</p>
            </MenuItem>
            <MenuItem>
                <IconButton
                    size="large"
                    aria-label="show 17 new notifications"
                    color="inherit"
                >
                    <Badge badgeContent={17} color="error">
                        <NotificationsIcon />
                    </Badge>
                </IconButton>
                <p>Notifications</p>
            </MenuItem>
            <MenuItem onClick={handleProfileMenuOpen}>
                <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="primary-search-account-menu"
                    aria-haspopup="true"
                    color="inherit"
                >
                    <AccountCircle />
                </IconButton>
                <p>Profile</p>
            </MenuItem>
        </Menu>
    );

    return (


        <>
            {/* <Box sx={{ flexGrow: 1 }}> */}
            <AppBar position="absolute" open={open}>
                <Toolbar
                    sx={{
                        pr: '24px'
                    }}>
                    <IconButton
                        edge='start'
                        color='inherit'
                        aria-label='open drawer'
                        onClick={toggleDrawer}
                        sx={{
                            marginRight: '36px',
                            ...(open && { display: 'none' })
                        }}>
                        <MenuIcon />
                    </IconButton>
                    <Typography
                        component='h1'
                        variant='h6'
                        color='inherit'
                        noWrap
                        sx={{ flexGrow: 1 }}>
                        Content Management System
                    </Typography>
                    {/* <IconButton color='inherit'> */}
                    {/* <LogoutIcon onClick={logOut} /> */}
                    {/* <LogoutIcon /> */}
                    {/* </IconButton> */}

                    <Box sx={{ flexGrow: 1 }} />
                    <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                        <IconButton size="large" aria-label="show 4 new mails" color="inherit">
                            <Badge badgeContent={4} color="error">
                                <MailIcon />
                            </Badge>
                        </IconButton>
                        <IconButton
                            size="large"
                            aria-label="show 17 new notifications"
                            color="inherit"
                        >
                            <Badge badgeContent={17} color="error">
                                <NotificationsIcon />
                            </Badge>
                        </IconButton>
                        <IconButton
                            size="large"
                            edge="end"
                            aria-label="account of current user"
                            aria-controls={menuId}
                            aria-haspopup="true"
                            onClick={handleProfileMenuOpen}
                            color="inherit"
                        >
                            <AccountCircle />
                        </IconButton>
                    </Box>
                    <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="show more"
                            aria-controls={mobileMenuId}
                            aria-haspopup="true"
                            onClick={handleMobileMenuOpen}
                            color="inherit"
                        >
                            <MoreIcon />
                        </IconButton>
                    </Box>
                </Toolbar>
            </AppBar>
            {renderMobileMenu}
            {renderMenu}
            {/* </Box> */}

            <Drawer variant='permanent' open={open}>
                <Toolbar
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        px: [1]
                    }}>
                    <IconButton onClick={toggleDrawer}>
                        <ChevronLeftIcon />
                    </IconButton>

                </Toolbar>
                <Divider />
                <List
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                    // subheader={
                    //     <ListSubheader component="div" id="nested-list-subheader">
                    //         Nested List Items
                    //     </ListSubheader>
                    // }
                    className={classes.root}
                >
                    <ListItem button disablePadding onClick={openDashboard}>
                        <ListItemIcon>
                            <DashboardIcon />
                        </ListItemIcon>
                        <ListItemText primary="Dashboard" />
                        {openMenuDashboard ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={!openMenuDashboard} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItem disablePadding>
                                <ListItemButton onClick={() => linkTo('/')}>
                                    <ListItemIcon>
                                        <SpaceDashboardIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Main" />
                                </ListItemButton>
                            </ListItem>
                        </List>
                        <List component="div" disablePadding>
                            <ListItem disablePadding>
                                <ListItemButton onClick={() => linkTo('/dashboard2')}>
                                    <ListItemIcon>
                                        <GridViewIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Curriculum" />
                                </ListItemButton>
                            </ListItem>
                        </List>
                    </Collapse>
                    <ListItem button disablePadding onClick={() => linkTo('/package')}>
                        <ListItemIcon>
                            <InventoryIcon />
                        </ListItemIcon>
                        <ListItemText primary="Packages" />
                    </ListItem>
                    {/* <ListItem button disablePadding onClick={() => linkTo('/courses')}>
                        <ListItemIcon>
                            <GolfCourseIcon />
                        </ListItemIcon>
                        <ListItemText primary="Courses" />
                    </ListItem> */}
                    <ListItem button disablePadding onClick={openCourse}>
                        <ListItemIcon>
                            <GolfCourseIcon />
                        </ListItemIcon>
                        <ListItemText primary="Courses" />
                        {openMenuCourse ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={!openMenuCourse} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItem disablePadding>
                                <ListItemButton onClick={() => linkTo('/courses')}>
                                    <ListItemIcon>
                                        <LocalLibraryIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="All Courses" />
                                </ListItemButton>
                            </ListItem>
                        </List>
                        <List component="div" disablePadding>
                            <ListItem disablePadding>
                                <ListItemButton onClick={() => linkTo('/regular')}>
                                    <ListItemIcon>
                                        <AccountTreeIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Regular" />
                                </ListItemButton>
                            </ListItem>
                        </List>
                        <List component="div" disablePadding>
                            <ListItem disablePadding>
                                <ListItemButton onClick={() => linkTo('/prakerja')}>
                                    <ListItemIcon>
                                        <Diversity3Icon />
                                    </ListItemIcon>
                                    <ListItemText primary="Prakerja" />
                                </ListItemButton>
                            </ListItem>
                        </List>
                    </Collapse>
                    {/* <ListItem button disablePadding onClick={() => linkTo('/category')}>
                        <ListItemIcon>
                            <FormatListBulletedIcon />
                        </ListItemIcon>
                        <ListItemText primary="Category" />
                    </ListItem> */}
                    <ListItem button disablePadding onClick={() => linkTo('/members')}>
                        <ListItemIcon>
                            <GroupsIcon />
                        </ListItemIcon>
                        <ListItemText primary="Members" />
                    </ListItem>
                    <ListItem button disablePadding onClick={() => linkTo('#')}>
                        <ListItemIcon>
                            <HistoryEduIcon />
                        </ListItemIcon>
                        <ListItemText primary="History" />
                    </ListItem>
                    <ListItem button disablePadding onClick={() => linkTo('/instructors')}>
                        <ListItemIcon>
                            <MilitaryTechIcon />
                        </ListItemIcon>
                        <ListItemText primary="Instructors" />
                    </ListItem>
                    <ListItem button disablePadding onClick={() => linkTo('/location')}>
                        <ListItemIcon>
                            <RoomIcon />
                        </ListItemIcon>
                        <ListItemText primary="Location" />
                    </ListItem>
                    <ListItem button disablePadding onClick={() => linkTo('/broadcast')}>
                        <ListItemIcon>
                            <BroadcastOnHomeIcon />
                        </ListItemIcon>
                        <ListItemText primary="Broadcast" />
                    </ListItem>
                    {/* <ListItem button disablePadding onClick={() => linkTo('/settings')}>
                        <ListItemIcon>
                            <SettingsIcon />
                        </ListItemIcon>
                        <ListItemText primary="Settings" />
                    </ListItem> */}
                    <ListItem button disablePadding onClick={openSetting}>
                        <ListItemIcon>
                            <SettingsIcon />
                        </ListItemIcon>
                        <ListItemText primary="Setting" />
                        {openMenuSetting ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={!openMenuSetting} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItem disablePadding>
                                <ListItemButton onClick={() => linkTo('/category')}>
                                    <ListItemIcon>
                                        <TuneIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Category" />
                                </ListItemButton>
                            </ListItem>
                        </List>
                        <List component="div" disablePadding>
                            <ListItem disablePadding>
                                <ListItemButton onClick={() => linkTo('/type')}>
                                    <ListItemIcon>
                                        <FormatListBulletedIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Type" />
                                </ListItemButton>
                            </ListItem>
                        </List>
                        <List component="div" disablePadding>
                            <ListItem disablePadding>
                                <ListItemButton onClick={() => linkTo('/users')}>
                                    <ListItemIcon>
                                        <PeopleIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Users" />
                                </ListItemButton>
                            </ListItem>
                        </List>
                    </Collapse>
                </List>
            </Drawer>
        </>
    );
}