import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles'
import { Button, InputBase } from '@material-ui/core'
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Stack from '@mui/material/Stack';

import { Link, Navigate } from "react-router-dom";

import { makeStyles } from '@material-ui/core/styles';

import Navbar from '../components/Navbar';

/** Import table */
// import { DataGrid } from '@mui/x-data-grid';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';

/******** import Lists ***********/
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';

/******** import all icons ***********/
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import IconButton from '@mui/material/IconButton'
import AddIcon from '@mui/icons-material/Add'
import SearchIcon from '@material-ui/icons/Search'
import SendIcon from '@mui/icons-material/Send';
import DetailsIcon from '@mui/icons-material/Details';
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import InventoryIcon from '@mui/icons-material/Inventory';


const columns = [
    { field: 'id', headerName: 'No', width: 50, headerAlign: 'center', align: 'center' },
    {
        field: 'courseName', headerName: 'Course Name', minWidth: 300, flex: 1, headerAlign: 'center', renderCell: (params) => {
            return (
                <>

                    {/* <Button xs={{ m: 2 }}
                        onClick={() => getCourseId(params.row.id)}
                        variant="contained" style={{ borderRadius: '28px' }}
                    >
                        {params.row.courseName}
                    </Button> */}
                    <Link to={"/courses/" + params.row.id}
                    >
                        <Button xs={{ m: 2 }} variant="contained" style={{ borderRadius: '28px' }}>{params.row.courseName}</Button>
                    </Link>

                </>
            );
        }
    },
    { field: 'categoryName', headerName: 'Category', minWidth: 150, flex: 1, headerAlign: 'center', align: 'center' },
    { field: 'rating', headerName: 'Rating', headerAlign: 'center', align: 'center' },
    { field: 'version', headerName: 'Version', headerAlign: 'center', align: 'center' },
    // { field: 'name_file', headerName: 'Name File', headerAign: 'center', align: 'center' },
    { field: 'ext', headerName: 'Extension', headerAlign: 'center', align: 'center' },
    { field: 'size', headerName: 'Size' },
    // { field: 'author', headerName: 'Instructor', minWidth: 150 },
    {
        field: 'actions', headerName: 'Actions', minWidth: 180, flex: 1, sortable: false, hideable: false, disableColumnMenu: true, headerAlign: 'center', align: 'center', renderCell: (params) => {
            return (
                <>
                    <Stack direction="row" spacing={1}>
                        <Button xs={{ m: 2 }}
                            onClick={() => editData(params.row.id)}
                            variant="contained" style={{ borderRadius: '28px' }}
                        >
                            Edit
                        </Button>
                        <Button
                            onClick={() => deleteData(params.row.id)}
                            variant="contained" style={{ borderRadius: '28px' }}
                        >
                            Delete
                        </Button>
                    </Stack>
                </>
            );
        }
    }
]

const rows = [
    { id: 1, courseName: 'Python Programming', categoryName: 'programming', rating: 5, version: 'v.1', name_file: 'python_for_beginner', ext: '.pdf', size: '10MB', author: 'Hans-Petter Halvorsen' },
    { id: 2, courseName: 'Python for Data Science', categoryName: 'programming', rating: 5, version: 'v.2', name_file: 'python_for_ds', ext: '.pdf', size: '2MB', author: 'Rudolfo' },
    { id: 3, courseName: 'AWS Certification', categoryName: 'certificate', rating: 4.8, version: 'v.1', name_file: 'aws_certificate_vol.1', ext: '.pdf', size: '11MB', author: 'AWS' },
    { id: 4, courseName: 'CISCO Certification', categoryName: 'certificate', rating: 4.2, version: '', name_file: 'cisco_certificate_vol.2', ext: '.pdf', size: '22MB', author: 'CISCO' },
    { id: 5, courseName: 'Belajar Desain Grafis', categoryName: 'prakerja', rating: 5, version: 'v.2', name_file: 'prakerja_grafis_v.1', ext: '.pdf', size: '3MB', author: 'BINUS' }
]

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary
}))

const useStyles = makeStyles(theme => ({
    table: {
        minWidth: 650
    },
    root: {
        // padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 400
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1
    },
    iconButton: {
        padding: 10
    },
    divider: {
        height: 28,
        margin: 4
    }
}))

const editData = (id) => {
    alert(`edit data ` + id)
}

const deleteData = (id) => {
    alert(`delete data ` + id)
}

const defaultTheme = createTheme()

const CourseRight = () => {

    const classes = useStyles()

    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(5);

    return (
        // <>
        //     <h1>Course Right page...</h1>
        // </>
        <ThemeProvider theme={defaultTheme}>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />

                <Navbar />
                <Box
                    component='main'
                    sx={{
                        backgroundColor: theme =>
                            theme.palette.mode === 'light'
                                ? theme.palette.grey[100]
                                : theme.palette.grey[900],
                        flexGrow: 1,
                        height: '100vh',
                        overflow: 'auto'
                    }}
                >
                    <Toolbar />
                    <Breadcrumbs aria-label="breadcrumb" sx={{ mt: 2, ml: 2, p: 1 }}>
                        {/* <Link underline="hover" color="inherit" href="/">
                                MUI
                            </Link> */}
                        <Link
                            underline="hover"
                            color="inherit"
                            to="/"
                        >
                            Home
                        </Link>
                        <Link
                            underline="hover"
                            color="inherit"
                            to="/instructors"
                        >
                            Instructors
                        </Link>
                        <Typography color="text.primary">Course Right</Typography>
                    </Breadcrumbs>
                    <Container maxWidth='xl' sx={{ mt: 1, mb: 3 }}>
                        {/* <Typography><h6><ChevronRightIcon />{' '}Courses</h6></Typography> */}
                        <Grid container spacing={3}>
                            {/* SECTION #1 */}
                            <Grid item xs={12} md={12} lg={12}>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    <Grid item>
                                        <Button variant="outlined"><AddIcon style={{ fontSize: '1em', marginRight: '5px' }} />Add Data</Button>{' '}
                                        {/* <Button variant="outlined" style={{ marginLeft: '10px' }}><FileDownloadIcon style={{ fontSize: '1em', marginRight: '5px' }} />Export</Button> */}
                                    </Grid>
                                    <Grid item>
                                        {/* <Paper component='form' className={classes.root} style={{ paddingLeft: '10px' }}>
                                            <InputBase
                                                className={classes.input}
                                                placeholder='Search instructors...'
                                            />
                                            <IconButton
                                                // type='submit'
                                                className={classes.iconButton}
                                                aria-label='search'
                                            >
                                                <SearchIcon />
                                            </IconButton>
                                        </Paper> */}

                                        <List sx={{ width: '100%', maxWidth: 420, bgcolor: 'background.paper' }}>
                                            <ListItem alignItems="flex-start">

                                                <ListItemText style={{width:'360px'}}
                                                    primary="Instructor ID : 10022"
                                                    secondary={
                                                        <React.Fragment>
                                                            <Typography
                                                                sx={{ display: 'inline' }}
                                                                component="span"
                                                                variant="body2"
                                                                color="text.primary"
                                                                style={{fontSize:'16px'}}
                                                            >
                                                                <b>Agustinus Eka Budiman</b>
                                                            </Typography><br />
                                                            Instructor for In-house | Status : <b>Active</b>
                                                        </React.Fragment>
                                                    }
                                                />
                                                <ListItemAvatar>
                                                    <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
                                                </ListItemAvatar>
                                            </ListItem>
                                        </List>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={12} lg={12}>
                                <Paper
                                    sx={{
                                        p: 2,
                                        display: 'flex',
                                        flexDirection: 'column'
                                    }}
                                >
                                    {/* Content #3 */}

                                    <div style={{ height: 380, width: '100%' }}>
                                        <DataGrid
                                            rows={rows}
                                            columns={columns}
                                            initialState={{
                                                pagination: {
                                                    paginationModel: { page: page, pageSize: limit },
                                                },
                                            }}
                                            pageSizeOptions={[5, 10, 15, 20, 25]}
                                            // slots={{ toolbar: GridToolbar }}
                                        // checkboxSelection 
                                        // columnVisibilityModel={{
                                        //     // Hide columns status and traderName, the other columns will remain visible
                                        //     courseName: true,
                                        //     categoryName: true,
                                        // }}   
                                        />
                                    </div>

                                </Paper>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
            </Box>
        </ThemeProvider>
    )
}

export default CourseRight;