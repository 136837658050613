import React from 'react';
import Typography from '@mui/material/Typography';
import { Link } from "react-router-dom";
import TitleIcon from '@mui/icons-material/Title';
import FeedIcon from '@mui/icons-material/Feed';
import Tooltip from '@mui/material/Tooltip';

const totalRegular = () => {
    return (
        <>
            {/* <h1>Total Regular</h1> */}
            <h6 style={{ textAlign: 'center', marginTop: '27px' }}>Total Course Regular</h6>
            <Typography component="p" variant="h2" style={{ paddingTop: '10px', textAlign: 'center' }}>
                <Tooltip arrow title="Check All Regular Courses">
                    <Link underline="hover" to="/regular" style={{ textDecoration: 'none', cursor: 'pointer', color: 'black' }}>13</Link>
                </Tooltip>
            </Typography>
            <div style={{ textAlign: 'center', paddingBottom: '25px' }}>
                <small><FeedIcon style={{ fontSize: '17px' }} /> Koding dan Pengembangan Web..</small>
            </div>
        </>
    )
}

export default totalRegular;