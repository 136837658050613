import * as React from 'react';
import { Routes, Route, Link } from 'react-router-dom';

import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import PeopleIcon from '@mui/icons-material/People'
import BarChartIcon from '@mui/icons-material/BarChart'
import LayersIcon from '@mui/icons-material/Layers'
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import AssignmentIcon from '@mui/icons-material/Assignment'
import Diversity3Icon from '@mui/icons-material/Diversity3';
import ChecklistIcon from '@mui/icons-material/Checklist';
import SettingsIcon from '@mui/icons-material/Settings';

export const mainListItems = (
    <>
    {/* <ListItem disablePadding> */}
    <ListItemButton component={Link} to="/">
      <ListItemIcon>
        <DashboardCustomizeIcon />
      </ListItemIcon>
      <ListItemText primary='Dashboard' />
    </ListItemButton>
    <ListItemButton component={Link} to="/course">
      <ListItemIcon>
        <MenuBookIcon />
      </ListItemIcon>
      <ListItemText primary='Course' />
    </ListItemButton>
    <ListItemButton component={Link} to="/users">
      <ListItemIcon>
        <Diversity3Icon />
      </ListItemIcon>
      <ListItemText primary='User' />
    </ListItemButton>
    <ListItemButton component={Link} to="/category">
      <ListItemIcon>
        <ChecklistIcon />
      </ListItemIcon>
      <ListItemText primary='Category' />
    </ListItemButton>
    <ListItemButton component={Link} to="#">
      <ListItemIcon>
        <SettingsIcon />
      </ListItemIcon>
      <ListItemText primary='Setting' />
    </ListItemButton>
    {/* </ListItem> */}
  </>
)


export const secondaryListItems = (
    <>
      <ListSubheader component='div' inset>
        Saved reports
      </ListSubheader>
      <ListItemButton>
        <ListItemIcon>
          <AssignmentIcon />
        </ListItemIcon>
        <ListItemText primary='Current month' />
      </ListItemButton>
      <ListItemButton>
        <ListItemIcon>
          <AssignmentIcon />
        </ListItemIcon>
        <ListItemText primary='Last quarter' />
      </ListItemButton>
      <ListItemButton>
        <ListItemIcon>
          <AssignmentIcon />
        </ListItemIcon>
        <ListItemText primary='year-end sale' />
      </ListItemButton>
    </>
  )