import React, { useState } from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';

/*** Import all icons ****/
import InfoIcon from '@mui/icons-material/Info';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';

/** Import table */
import {
    GridRowModes,
    DataGrid,
    GridToolbarContainer,
    GridActionsCellItem,
    GridRowEditStopReasons,
    GridToolbarExport
} from '@mui/x-data-grid';
import {
    randomCreatedDate,
    randomTraderName,
    randomId,
    randomArrayItem,
} from '@mui/x-data-grid-generator';

import { Link, Navigate } from "react-router-dom";

import { makeStyles } from '@material-ui/core/styles';

import Navbar from '../components/NewNavbar';
import { Typography } from '@mui/material';

/**** Add Sweetalert */
import Swal from 'sweetalert2'


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary
}))

const useStyles = makeStyles(theme => ({
    table: {
        minWidth: 650
    },
    root: {
        // padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 400
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1
    },
    iconButton: {
        padding: 10
    },
    divider: {
        height: 28,
        margin: 4
    }
}))

const roles = ['Market', 'Finance', 'Development'];
const randomRole = () => {
    return randomArrayItem(roles);
};

const initialRows = [
    { id: 1, courseName: 'Python Programming', categoryName: 'programming', rating: 5, version: 'v.1', name_file: 'python_for_beginner', ext: '.pdf', size: '10MB', author: 'Hans-Petter Halvorsen' },
    { id: 2, courseName: 'Meningkatkan Penggunaan Fungsi Excel dalam Membangun Mekanisme Pelaporan yang Lebih Baik: Panduan Teknis dan Analitis', categoryName: 'programming', rating: 5, version: 'v.2', name_file: 'python_for_ds', ext: '.pdf', size: '2MB', author: 'Rudolfo' },
    { id: 3, courseName: 'AWS Certification', categoryName: 'certificate', rating: 4.8, version: 'v.1', name_file: 'aws_certificate_vol.1', ext: '.pdf', size: '11MB', author: 'AWS' },
    { id: 4, courseName: 'CISCO Certification', categoryName: 'certificate', rating: 4.2, version: '', name_file: 'cisco_certificate_vol.2', ext: '.pdf', size: '22MB', author: 'CISCO' },
    { id: 5, courseName: 'Belajar Desain Grafis', categoryName: 'prakerja', rating: 5, version: 'v.2', name_file: 'prakerja_grafis_v.1', ext: '.pdf', size: '3MB', author: 'BINUS' }
]

function EditToolbar(props) {
    const { setRows, setRowModesModel } = props;

    const handleClick = () => {
        // const id = randomId();
        // setRows((oldRows) => [...oldRows, { id, name: '', age: '', isNew: true }]);
        // setRowModesModel((oldModel) => ({
        //   ...oldModel,
        //   [id]: { mode: GridRowModes.Edit, fieldToFocus: 'name' },
        // }));

        alert('add data!')
    };

    return (
        <GridToolbarContainer>
            <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
                Add record
            </Button>
            <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
        </GridToolbarContainer>
    );
}

const defaultTheme = createTheme()

export default function RegularCourseNew() {

    const classes = useStyles()

    const [rows, setRows] = React.useState(initialRows);
    const [rowModesModel, setRowModesModel] = React.useState({});

    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(5);

    const handleRowEditStop = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }
    };

    const handleEditClick = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };

    const handleSaveClick = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };

    const handleDeleteClick = (id) => () => {

        // setRows(rows.filter((row) => row.id !== id));

        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                setRows(rows.filter((row) => row.id !== id));
                Swal.fire(
                    'Deleted!',
                    'Your file has been deleted.',
                    'success'
                )
            }
        })

    };

    const handleCancelClick = (id) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });

        const editedRow = rows.find((row) => row.id === id);
        if (editedRow.isNew) {
            setRows(rows.filter((row) => row.id !== id));
        }
    };

    const processRowUpdate = (newRow) => {
        const updatedRow = { ...newRow, isNew: false };
        setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
        return updatedRow;
    };

    const handleRowModesModelChange = (newRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };

    const columns = [
        { field: 'id', headerName: 'No', width: 50, headerAlign: 'center', align: 'center' },
        {
            field: 'courseName', headerName: 'Course Name', minWidth: 320, flex: 1, headerAlign: 'center', editable: true, renderCell: (params) => {
                return (
                    <>
                        <Tooltip arrow title={params.row.courseName}>
                            {params.row.courseName}
                        </Tooltip>
                    </>
                )
            }
        },
        { field: 'categoryName', headerName: 'Category', minWidth: 100, flex: 1, headerAlign: 'center', align: 'center' },
        { field: 'rating', headerName: 'Rating', headerAlign: 'center', align: 'center' },
        { field: 'version', headerName: 'Version', headerAlign: 'center', align: 'center' },
        // { field: 'name_file', headerName: 'Name File', headerAign: 'center', align: 'center' },
        { field: 'ext', headerName: 'Extension', headerAlign: 'center', align: 'center' },
        { field: 'size', headerName: 'Size' },
        { field: 'author', headerName: 'Instructor', headerAlign: 'center', align: 'center', minWidth: 150 },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 100,
            cellClassName: 'actions',
            getActions: ({ id, courseName }) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<SaveIcon />}
                            label="Save"
                            sx={{
                                color: 'primary.main',
                            }}
                            onClick={handleSaveClick(id)}
                        />,
                        <GridActionsCellItem
                            icon={<CancelIcon />}
                            label="Cancel"
                            className="textPrimary"
                            onClick={handleCancelClick(id)}
                            color="inherit"
                        />,
                    ];
                }

                return [
                    <Link to={"/courses/" + id}
                    >
                        {/* <Tooltip arrow title={params.row.courseName}>
                            <Button xs={{ m: 2 }} variant="contained" style={{ borderRadius: '28px' }}>
                                {string}
                            </Button>
                        </Tooltip> */}
                        {/* {params.row.courseName} */}
                        <InfoIcon />
                    </Link>,
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Edit"
                        className="textPrimary"
                        onClick={handleEditClick(id)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Delete"
                        onClick={handleDeleteClick(id)}
                        color="inherit"
                    />,
                ];
            },
        },
    ]


    return (

        <ThemeProvider theme={defaultTheme}>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />

                <Navbar />
                <Box
                    component='main'
                    sx={{
                        backgroundColor: theme =>
                            theme.palette.mode === 'light'
                                ? theme.palette.grey[100]
                                : theme.palette.grey[900],
                        flexGrow: 1,
                        height: '100vh',
                        overflow: 'auto'
                    }}
                >
                    <Toolbar />
                    <Breadcrumbs aria-label="breadcrumb" sx={{ mt: 2, ml: 2, p: 1 }}>
                        {/* <Link underline="hover" color="inherit" href="/">
                                MUI
                            </Link> */}
                        <Link
                            underline="hover"
                            color="inherit"
                            to="/"
                        >
                            Home
                        </Link>
                        <Typography color="text.primary">All Courses</Typography>
                    </Breadcrumbs>

                    <Container maxWidth='xl' sx={{ mt: 1, mb: 3 }}>
                        {/* <Typography><h6><ChevronRightIcon />{' '}Courses</h6></Typography> */}
                        <Grid container spacing={3}>
                            {/* SECTION #1 */}
                            <Grid item xs={12} md={12} lg={12}>

                                <Box
                                    sx={{
                                        height: 500,
                                        width: '100%',
                                        '& .actions': {
                                            color: 'text.secondary',
                                        },
                                        '& .textPrimary': {
                                            color: 'text.primary',
                                        },
                                    }}
                                >
                                    <Paper
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column'
                                        }}>
                                        <DataGrid
                                            rows={rows}
                                            columns={columns}
                                            editMode="row"
                                            rowModesModel={rowModesModel}
                                            onRowModesModelChange={handleRowModesModelChange}
                                            onRowEditStop={handleRowEditStop}
                                            processRowUpdate={processRowUpdate}
                                            slots={{
                                                toolbar: EditToolbar,
                                            }}
                                            slotProps={{
                                                toolbar: { setRows, setRowModesModel },
                                            }}

                                            initialState={{
                                                pagination: {
                                                    paginationModel: {
                                                        page: page,
                                                        pageSize: limit
                                                    }
                                                },
                                                columns: {
                                                    columnVisibilityModel: {
                                                        rating: false,
                                                        version: false
                                                    }
                                                }
                                            }}
                                            pageSizeOptions={[5, 10, 25, 50, 100]}
                                        />
                                    </Paper>
                                </Box>

                            </Grid>
                        </Grid>
                    </Container>

                </Box>
            </Box>
        </ThemeProvider >
    );
}
