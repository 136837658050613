import axios from 'axios';
import authHeader from './authHeader';

import API_URL from '../config/baseURL';

const getAllCategory = async () => {
    try {
        const response = await axios.get(API_URL + 'category', {
            headers: authHeader()
        })
        console.log(response.data);
        return response.data
        
    } catch (error) {
        throw error
    }
}

const getCategory = {
    getAllCategory
}

export default getCategory