import React from 'react';
import Typography from '@mui/material/Typography';
import { Link } from "react-router-dom";

const progressRegular = () => {
    return (
        <>
            {/* <h1>Progress Regular</h1> */}
            <h6 style={{ textAlign: 'center', marginTop: '27px' }}>Course Progress Regular</h6>
            <Typography component="p" variant="h2" style={{ paddingTop: '10px', textAlign: 'center' }}>
                0.00%
            </Typography>
            <div style={{ textAlign: 'center', paddingBottom: '25px' }}>
                <small>Last update 7 Sep 2023</small>
            </div>
        </>
    )
}

export default progressRegular;