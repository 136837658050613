import * as React from 'react';
// import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { Link } from "react-router-dom";


// function preventDefault(event) {
//     event.preventDefault()
// }

export default function courseTotal() {

    return (
        <>
            {/* <div style={{ marginTop: '20px' }}> */}
                <h5 style={{textAlign:'center', marginTop:'20px'}}>Total Courses</h5>
                <Typography component='p' variant='h3' style={{paddingTop:'10px', textAlign:'center'}}>
                    133
                </Typography>
                <Typography color='text.secondary' sx={{ flex: 1 }} style={{paddingTop:'5px', textAlign:'center'}}>
                    on 3 Aug, 2023
                </Typography>
            {/* </div> */}
            <div style={{ textAlign: 'center', position: 'bottom' }}>
                <Link  to="/courses" color="primary">
                    View all
                </Link>
            </div>

        </>
    )
}