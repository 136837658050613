import axios from 'axios';
import authHeader from './authHeader';

import API_URL from '../config/baseURL';

const getAllType = async () => {
    try {
        const response = await axios.get(API_URL + 'type', {
            headers: authHeader()
        })
        
        return response.data
    } catch (error) {
        throw error
    }
}

const getType = {
    getAllType
}

export default getType