import React, { useState, useRef } from 'react'
import axios from 'axios'
import 'bootstrap/dist/css/bootstrap.min.css'

import { useNavigate } from 'react-router-dom'
import Form from 'react-validation/build/form'
import Input from 'react-validation/build/input'
import CheckButton from 'react-validation/build/button'

import AuthService from '../services/authService'

import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Link from '@mui/material/Link'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import Paper from '@mui/material/Paper'

function Copyright(props) {
    return (
        <Typography
            variant='body2'
            color='text.secondary'
            align='center'
            {...props}
        >
            {'Copyright © '}
            <Link color='inherit' href='https://mui.com/'>
                Adhitya Dwijayantho
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    )
}

const defaultTheme = createTheme()

const required = value => {
    if (!value) {
        return (
            <div className='alert alert-danger' role='alert'>
                This field is required
            </div>
        )
    }
}

const Login = () => {
    const navigate = useNavigate()

    const form = useRef()
    const checkBtn = useRef()

    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState('')

    const onChangeUsername = e => {
        const username = e.target.value
        setUsername(username)
    }

    const onChangePassword = e => {
        const password = e.target.value
        setPassword(password)
    }

    const handleSubmit = event => {
        event.preventDefault()
        // const data = new FormData(event.currentTarget)
        // console.log({
        //   username: data.get('username'),
        //   password: data.get('password')
        // })
        setMessage('')
        setLoading(true)

        form.current.validateAll()

        if (checkBtn.current.context._errors.length === 0) {
            AuthService.login(username, password).then(
                () => {
                    navigate('/')
                    window.location.reload()
                },
                error => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString()

                    setLoading(false)
                    setMessage(resMessage)
                }
            )
        } else {
            setLoading(false)
        }
    }

    return (
        <ThemeProvider theme={defaultTheme}>
            <Container component='main' maxWidth='xs'>
                <CssBaseline />
                {/* <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square> */}
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component='h1' variant='h5'>
                        Sign in
                    </Typography>
                    <Form onSubmit={handleSubmit} ref={form}>
                        <Box noValidate xs={{ mt: 1 }}>

                            <Input
                                style={{
                                    marginTop: '35px',
                                    marginBottom: '20px',
                                    border: '1px solid lightgrey',
                                    height: '45px'
                                }}
                                type='text'
                                className='form-control'
                                name='username'
                                value={username}
                                onChange={onChangeUsername}
                                validations={[required]}
                                placeholder='Username...'
                                autoFocus
                            />

                            <Input
                                style={{
                                    marginTop: '10px',
                                    marginBottom: '10px',
                                    border: '1px solid lightgrey',
                                    height: '45px'
                                }}
                                type='password'
                                className='form-control'
                                name='password'
                                value={password}
                                onChange={onChangePassword}
                                validations={[required]}
                                placeholder='Password...'
                            />
                            <FormControlLabel
                                style={{ paddingLeft: '10px', paddingTop: '24px' }}
                                control={<Checkbox value='remember' color='primary' />}
                                label='Remember me'
                            />
                            {/* <Button
              type='submit'
              fullWidth
              variant='contained'
              sx={{ mt: 3, mb: 2 }}
            >
              Sign in
            </Button> */}

                            <button
                                className='btn btn-primary btn-block'
                                fullWidth
                                variant='contained'
                                sx={{ mt: 4, mb: 1 }}
                                disabled={loading}
                            >
                                {' '}
                                {loading && (
                                    <span className='spinner-border spinner-border-sm'></span>
                                )}
                                <span>{' '}Sign In</span>
                            </button>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Link href='#' variant='body2'>
                                        Forgot Password?
                                    </Link>
                                </Grid>
                                <br />
                                <Grid item xs={12}>
                                    <p>
                                        Don't have an account?{' '}
                                        <Link href='/register' variant='body2'>
                                            Sign up
                                        </Link>
                                    </p>
                                </Grid>
                            </Grid>
                            <Copyright sx={{ mt: 5 }} />

                            {message && (
                                <div className='form-group'>
                                    <div className='alert alert-danger' role='alert'>
                                        {message}
                                    </div>
                                </div>
                            )}
                            <CheckButton style={{ display: 'none' }} ref={checkBtn} />
                        </Box>
                    </Form>
                </Box>

            </Container>
        </ThemeProvider>
    )
}

export default Login
